import { SignUpComponent } from 'components';
import { APP_ROUTES } from 'constants/AppRoutes';

const SignUpPandit = () => (
	<SignUpComponent
		dashboardLink={APP_ROUTES.DASHBOARD_PANDIT}
		signInLink={APP_ROUTES.SIGN_IN_PANDIT}
	/>
);

export default SignUpPandit;
