import { AxiosInstance } from 'axios';

export const AxiosRequestInterceptor = (axiosInstance: AxiosInstance) => {
	// * Add a request interceptor
	axiosInstance.interceptors.request.use(
		(config) => config,
		(error) => Promise.reject(error)
	);
};

export default AxiosRequestInterceptor;
