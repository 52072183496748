import * as yup from 'yup';
import { emailRegex, mobileRegex } from './regex';

/*//! function to test user input type whether it is email or mobile  */
const functionToTestIfInputIsEmailOrMobile = (value: string) => {
	const isValidEmail = emailRegex.test(value);
	const isValidMobile = mobileRegex.test(value);
	if (!isValidEmail && !isValidMobile) {
		return false;
	}
	return true;
};

/*//! common password schema */
const passwordSchema = yup
	.string()
	.required('password cannot be empty')
	.min(6, 'password must be at least 6 characters long')
	.max(30, 'password must be at most 30 characters long')
	.trim()
	.ensure();

/*//! common emailOrMobile schema */
const emailOrMobileSchema = yup
	.string()
	.required('please enter your email address or mobile number')
	.test(
		'validating-email-or-mobile',
		'email address or mobile number is invalid, please enter a valid email or mobile number',
		(value = '') => functionToTestIfInputIsEmailOrMobile(value)
	)
	.trim()
	.ensure();

/*//! common name schema */
const nameSchema = yup
	.string()
	.required('please enter your name')
	.max(40, 'name must be at most 40 characters long')
	.trim()
	.ensure();

/*//! common email schema */
const emailSchema = yup
	.string()
	.required('please enter your name')
	.max(40, 'name must be at most 40 characters long')
	.trim()
	.ensure();

/*//! common mobile schema */
const mobileSchema = yup
	.string()
	.required('please enter a mobile number')
	.matches(mobileRegex, {
		message: 'mobile number is invalid, please enter a valid 10 digit mobile number',
		excludeEmptyString: true,
	})
	.trim()
	.ensure();

/* Schema to use for validating user input while signing-up */
const SignUpValidationSchema = yup.object().shape({
	name: nameSchema,
	email: emailSchema,
	mobile: mobileSchema,
	password: passwordSchema,
});

/* Schema to use for validating user input while signing-in */
const SignInValidationSchema = yup.object().shape({
	emailOrMobile: emailOrMobileSchema,
	password: passwordSchema,
});

/* Schema to use for validating user input while updating profile */
const ProfileValidationSchema = yup.object().shape({
	name: nameSchema,
	gender: yup.string().trim().ensure(),
	address: yup.string().max(200, 'address must be at most 200 characters long').trim().ensure(),
	email: emailSchema,
	mobile: mobileSchema,
});

const PasswordResetLinkCreationValidationSchema = yup.object({
	emailOrMobile: emailOrMobileSchema,
});

const SetNewPasswordValidationSchema = yup.object({
	newPassword: passwordSchema,
});

export {
	SignUpValidationSchema,
	SignInValidationSchema,
	ProfileValidationSchema,
	PasswordResetLinkCreationValidationSchema,
	SetNewPasswordValidationSchema,
};
