import { Card, CardContent, CardHeader, Divider } from '@mui/material';
import { CardProps } from '@mui/material/Card';
import { styled } from '@mui/material/styles';

type MuiCardProps = {
	children: React.ReactNode;
	height?: number;
	enableOverflow?: boolean;
	padding?: number;
	header?: string;
};

const MuiCard = (props: MuiCardProps) => {
	const CustomCard = styled(Card)<CardProps>(({ theme }) => ({
		height: '100%',
	}));
	const CustomCardContent = styled(CardContent)<CardProps>(({ theme }) => ({
		padding: `${props.padding ?? 1}rem`,
	}));

	return (
		<CustomCard raised>
			{props?.header && (
				<>
					<CardHeader title={props.header} />
					<Divider />
				</>
			)}
			<CustomCardContent>{props.children}</CustomCardContent>
		</CustomCard>
	);
};

export default MuiCard;
