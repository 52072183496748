import { DashboardComponent } from 'components';
import { useProfileAdmin } from 'hooks';
import { useEffect } from 'react';

const DashboardAdmin = () => {
	const { fetchProfileAdmin } = useProfileAdmin();

	useEffect(() => {
		fetchProfileAdmin();
	}, []);

	return <DashboardComponent />;
};

export default DashboardAdmin;
