import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';

type TypewriterTypographyProps = {
	children: React.ReactNode;
	height?: string;
	enableOverflow?: boolean;
	padding?: number;
	variant?:
		| 'h1'
		| 'h2'
		| 'h3'
		| 'h4'
		| 'h5'
		| 'h6'
		| 'subtitle1'
		| 'subtitle2'
		| 'body1'
		| 'body2'
		| 'caption'
		| 'button'
		| 'overline';
	characterWidth: number;
	animationDuration?: number;
	animationDelay?: number;
	cursorBlinkDuration?: number;
	fontSize: number;
};

const TypewriterTypography = (props: TypewriterTypographyProps) => {
	let fontSizeMobile = props.fontSize / 2;
	const CustomTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
		fontSize: `clamp(${fontSizeMobile}rem, 2.5vw,${props.fontSize}rem)`,
		width: `${props?.characterWidth}ch`,
		borderRight: `3px solid`,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		animation: `typewriter ${
			//! checking if animationDuration has any value or not if yes apply that value otherwise apply default value of 3
			props?.animationDuration ? props.animationDuration : 3
		}s steps(44) ${
			//! checking if animationDelay has any value or not if yes apply that value otherwise apply default value of 0
			props?.animationDelay ? props.animationDelay : 0
		}s 1 normal both,
		  blinkTextCursor ${
				//! checking if cursorBlinkDuration has any value or not if yes apply that value otherwise apply default value of 1
				props?.cursorBlinkDuration ? props.cursorBlinkDuration : 1
			}s steps(44) infinite normal
			`,
		// //! keyframes
		'@keyframes typewriter': {
			from: {
				width: 0,
			},
		},
		'@keyframes blinkTextCursor': {
			from: {
				borderRightColor: theme.palette.grey[500],
			},
			to: {
				borderRightColor: 'transparent',
			},
		},
	}));

	return (
		<Box display={'flex'} justifyContent={'center'}>
			<CustomTypography variant={props?.variant}>{props?.children}</CustomTypography>
		</Box>
	);
};

export default TypewriterTypography;
