const ADMIN_AUTH = `admin/auth`;
const ADMIN_PROFILE = `admin/profile`;
const PANDIT_AUTH = `pandit/auth`;
const PANDIT_PROFILE = `pandit/profile`;
const USER_AUTH = `user/auth`;
const USER_PROFILE = `user/profile`;

const SIGN_UP = `/sign-up/`;
const SIGN_IN = `/sign-in/`;
const SIGN_OUT = `/sign-out/`;
const CREATE_PASSWORD_RESET_LINK = `/create-password-reset-link/`;
const SET_NEW_PASSWORD = `/set-new-password/`;
const REFRESH_TOKEN = `/refresh-token/`;
const CREATE_MOBILE_VERIFICATION_LINK = `/create-mobile-verification-link/`;
const CREATE_EMAIL_VERIFICATION_LINK = `/create-email-verification-link/`;

const API_ENDPOINTS = {
	/* ADMIN RELATED ENDPOINTS */
	SIGN_UP_ADMIN: `${ADMIN_AUTH}${SIGN_UP}`,
	SIGN_IN_ADMIN: `${ADMIN_AUTH}${SIGN_IN}`,
	REFRESH_JWT_ADMIN: `${ADMIN_AUTH}${REFRESH_TOKEN}`,
	SIGN_OUT_ADMIN: `${ADMIN_AUTH}${SIGN_OUT}`,
	CREATE_MOBILE_VERIFICATION_LINK_ADMIN: `${ADMIN_AUTH}${CREATE_MOBILE_VERIFICATION_LINK}`,
	CREATE_EMAIL_VERIFICATION_LINK_ADMIN: `${ADMIN_AUTH}${CREATE_EMAIL_VERIFICATION_LINK}`,
	CREATE_PASSWORD_RESET_LINK_ADMIN: `${ADMIN_AUTH}${CREATE_PASSWORD_RESET_LINK}`,
	SET_NEW_PASSWORD_ADMIN: `${ADMIN_AUTH}${SET_NEW_PASSWORD}`,

	/* PROFILE */
	PROFILE_ADMIN: ADMIN_PROFILE,

	/* PANDIT RELATED ENDPOINTS */
	SIGN_UP_PANDIT: `${PANDIT_AUTH}${SIGN_UP}`,
	SIGN_IN_PANDIT: `${PANDIT_AUTH}${SIGN_IN}`,
	REFRESH_JWT_PANDIT: `${PANDIT_AUTH}${REFRESH_TOKEN}`,
	SIGN_OUT_PANDIT: `${PANDIT_AUTH}${SIGN_OUT}`,
	CREATE_MOBILE_VERIFICATION_LINK_PANDIT: `${PANDIT_AUTH}${CREATE_MOBILE_VERIFICATION_LINK}`,
	CREATE_EMAIL_VERIFICATION_LINK_PANDIT: `${PANDIT_AUTH}${CREATE_EMAIL_VERIFICATION_LINK}`,
	CREATE_PASSWORD_RESET_LINK_PANDIT: `${PANDIT_AUTH}${CREATE_PASSWORD_RESET_LINK}`,
	SET_NEW_PASSWORD_PANDIT: `${PANDIT_AUTH}${SET_NEW_PASSWORD}`,

	/* PROFILE */
	PROFILE_PANDIT: PANDIT_PROFILE,

	/* USER RELATED ENDPOINTS */
	SIGN_UP_USER: `${USER_AUTH}${SIGN_UP}`,
	SIGN_IN_USER: `${USER_AUTH}${SIGN_IN}`,
	REFRESH_JWT_USER: `${USER_AUTH}${REFRESH_TOKEN}`,
	SIGN_OUT_USER: `${USER_AUTH}${SIGN_OUT}`,
	CREATE_MOBILE_VERIFICATION_LINK_USER: `${USER_AUTH}${CREATE_MOBILE_VERIFICATION_LINK}`,
	CREATE_EMAIL_VERIFICATION_LINK_USER: `${USER_AUTH}${CREATE_EMAIL_VERIFICATION_LINK}`,
	CREATE_PASSWORD_RESET_LINK_USER: `${USER_AUTH}${CREATE_PASSWORD_RESET_LINK}`,
	SET_NEW_PASSWORD_USER: `${USER_AUTH}${SET_NEW_PASSWORD}`,

	/* PROFILE */
	PROFILE_USER: USER_PROFILE,
};
export default API_ENDPOINTS;
