import { IconButton, Tooltip, Typography } from '@mui/material';
import { GoUnverified, GoVerified } from 'react-icons/go';

const VerifiedTooltip = () => (
	<Tooltip title='Profile verified' arrow placement='top'>
		<IconButton color='success'>
			<GoVerified />
		</IconButton>
	</Tooltip>
);

const UnVerifiedTooltip = () => (
	<Tooltip title='Profile unverified' arrow placement='top'>
		<IconButton color='error'>
			<GoUnverified />
		</IconButton>
	</Tooltip>
);

export { VerifiedTooltip, UnVerifiedTooltip };
