import { SignInComponent } from 'components';
import { APP_ROUTES } from 'constants/AppRoutes';

const SignInAdmin = () => (
	<SignInComponent
		resetPasswordLink={APP_ROUTES.CREATE_PASSWORD_RESET_LINK_ADMIN}
		dashboardLink={APP_ROUTES.DASHBOARD_ADMIN}
		signUpLink={APP_ROUTES.SIGN_UP_ADMIN}
	/>
);

export default SignInAdmin;
