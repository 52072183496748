import { AppBar, Box, Grid, Tab, Tabs } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CATALOGUE } from 'constants/Catalogue';
import { PAATHS } from 'constants/Paaths';
import { PUJAS } from 'constants/Pujas';
import { SyntheticEvent, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import PujaCard from './PujaCard';

type TabPanelProps = {
	children?: React.ReactNode;
	dir?: string;
	index: number;
	value: number;
};

const TabPanel = (props: TabPanelProps) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}>
			{value === index && <Box sx={{ p: 1 }}>{children}</Box>}
		</div>
	);
};

const a11yProps = (index: number) => {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
};

const Puja = () => {
	// TODO: this array will be set inside useEffect when data starts coming from backend
	let PUJA_ARRAY = [PUJAS, PAATHS]; // pushing all the different puja types array into single array

	const theme = useTheme();
	const [value, setValue] = useState(0);

	const handleChange = (event: SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	const handleChangeIndex = (index: number) => {
		setValue(index);
	};

	return (
		<>
			<AppBar position='static' color='inherit'>
				<Tabs
					value={value}
					onChange={handleChange}
					variant='scrollable'
					scrollButtons='auto'
					aria-label='scrollable catalogue'
					centered>
					{/* TODO: fetch this list from backend */}
					{CATALOGUE.map((catalogue) => (
						<Tab
							key={catalogue.key}
							label={catalogue.title}
							{...a11yProps(catalogue.key)}
							// icon={<catalogue.icon />}
							// iconPosition='start'
						/>
					))}
				</Tabs>
			</AppBar>

			<SwipeableViews
				axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
				index={value}
				onChangeIndex={handleChangeIndex}>
				{/* TODO: map over list of pujas and render TABPANEL w.r.t TABS */}
				{PUJA_ARRAY.map((pujas, index) => (
					<TabPanel value={value} index={index} dir={theme.direction} key={index}>
						<Grid container spacing={3}>
							{pujas.map((puja) => (
								<Grid item xs={12} sm={6} md={4} lg={3} key={puja.key}>
									<PujaCard data={puja} />
								</Grid>
							))}
						</Grid>
					</TabPanel>
				))}
			</SwipeableViews>
		</>
	);
};
export default Puja;
