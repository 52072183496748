import API_ENDPOINTS from 'constants/ApiEndpoints';
import { APP_ROUTES } from 'constants/AppRoutes';
import { useNavigate } from 'react-location';
import { useAppDispatch, useAppSelector } from 'store';
import {
	adminDataInStore,
	saveAdminProfileReducer,
	signInAdminReducer,
} from 'store/admin/adminSlice';
import { activeRoleReducer, errorReducer, successReducer } from 'store/app/appSlice';
import useAxios from 'utils/useAxios';

const useAuthAdmin = () => {
	const { axiosRequest } = useAxios();

	const navigate = useNavigate();
	const { isAdminSignedIn } = useAppSelector(adminDataInStore);
	const dispatch = useAppDispatch();

	// dispatch function that will set the user data in redux store and local storage while redirecting them to dashboard
	const dispatchFunc = (data?: {}) => {
		dispatch(
			signInAdminReducer({
				...data,
			})
		);
		dispatch(activeRoleReducer({ role: 'admin' })); // TODO: change this to something that is hard to guess for malicious users in production
		navigate({ to: APP_ROUTES.DASHBOARD_ADMIN });
	};

	// function that will sign out the user from client as well as server
	const signOutFunction = () => {
		// trigger sign out only when admin is signed in
		if (!isAdminSignedIn) return;
		axiosRequest({ url: API_ENDPOINTS.SIGN_OUT_ADMIN, method: 'delete' })
			.then((response) => {
				if (response.status === 204) {
					dispatch(
						signInAdminReducer({
							access_token: '',
						})
					);
					dispatch(saveAdminProfileReducer({}));
					dispatch(activeRoleReducer({ role: '' }));
				}
			})
			.catch((error) => {
				console.log('🚀 ~ file: useAuthAdmin.ts ~ line 38 ~ signOutFunction ~ error', error);
			});
	};

	// * sign up function

	const signUpAdmin = (data: {}, setErrors: (fields: { [field: string]: string }) => void) =>
		axiosRequest({ url: API_ENDPOINTS.SIGN_UP_ADMIN, method: 'post', data })
			.then((response) => {
				dispatchFunc(response.data);
			})
			.catch((error) => {
				// setting errors for formik to display
				setErrors({
					name: error.response.data.error?.message?.name,
					email: error.response.data.error?.message?.email,
					mobile: error.response.data.error?.message?.mobile,
					password: error.response.data.error?.message?.password,
				});

				// only store error to redux if status code is not 422 or else the code will break because of typescript
				if (error.response.data.error.status === 422) return;
				// setting errors for redux store to display on top of form. useful for displaying not found error
				dispatch(errorReducer(error.response.data.error));
			});

	// * sign in function

	const signInAdmin = (data: {}, setErrors: (fields: { [field: string]: string }) => void) =>
		axiosRequest({ url: API_ENDPOINTS.SIGN_IN_ADMIN, method: 'post', data })
			.then((response) => {
				dispatchFunc(response.data);
			})
			.catch((error) => {
				// setting errors for formik to display
				setErrors({
					emailOrMobile: error.response.data.error?.message?.emailOrMobile,
					password: error.response.data.error?.message?.password,
				});

				// only store error to redux if status code is not 422 or else the code will break because of typescript
				if (error.response.data.error.status === 422) return;
				// setting errors for redux store to display on top of form. useful for displaying not found error
				dispatch(errorReducer(error.response.data.error));
			});

	// * refresh token function

	const refreshJwtAdmin = () =>
		axiosRequest({ url: API_ENDPOINTS.REFRESH_JWT_ADMIN, method: 'post' })
			.then((response) => {
				//  not using dispatch function declared on top as it has a redirect and we dont want admin to be redirected to dashboard on each token refresh
				dispatch(
					signInAdminReducer({
						...response.data,
					})
				);
			})
			.catch((error) => {
				dispatch(errorReducer(error.response.data.error));
			});

	// * sign out function

	const signOutAdmin = () => {
		signOutFunction();
		navigate({ to: APP_ROUTES.HOME });
	};

	// * sign out function to use when logging in other two users

	const signOutAdminWithoutRedirect = () => {
		signOutFunction();
	};

	// * password reset function

	const passwordResetAdmin = (
		data: {},
		setErrors: (fields: { [field: string]: string }) => void
	) => {
		axiosRequest({
			url: API_ENDPOINTS.CREATE_PASSWORD_RESET_LINK_ADMIN,
			method: 'post',
			data,
		})
			.then((response) => {
				dispatch(successReducer(response.data));
			})
			.catch((error) => {
				setErrors({
					emailOrMobile: error.response.data.error?.message?.emailOrMobile,
				});
				if (error.response.data.error.status === 422) return;
				dispatch(errorReducer(error.response.data.error));
			});
	};

	// * password reset function

	const setNewPasswordAdmin = (
		data: {},
		setErrors: (fields: { [field: string]: string }) => void
	) => {
		axiosRequest({
			url: API_ENDPOINTS.SET_NEW_PASSWORD_ADMIN,
			method: 'post',
			data,
		})
			.then((response) => {
				dispatch(successReducer(response.data));
			})
			.catch((error) => {
				setErrors({
					newPassword: error.response.data.error?.message?.newPassword,
				});
				if (error.response.data.error.status === 422) return;
				dispatch(errorReducer(error.response.data.error));
			});
	};

	return {
		signUpAdmin,
		signInAdmin,
		signOutAdmin,
		refreshJwtAdmin,
		passwordResetAdmin,
		setNewPasswordAdmin,
		signOutAdminWithoutRedirect,
		isAdminSignedIn,
	};
};

export default useAuthAdmin;
