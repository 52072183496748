import axios, { AxiosError, AxiosResponse } from 'axios';
import { AxiosInstanceConfig } from './AxiosInstanceConfig';
import AxiosRequestInterceptor from './AxiosRequestInterceptor';
import AxiosResponseInterceptor from './AxiosResponseInterceptor';

const useAxios = () => {
	const axiosInstance = axios.create(AxiosInstanceConfig);

	AxiosRequestInterceptor(axiosInstance);
	AxiosResponseInterceptor(axiosInstance);

	const axiosRequest = ({ ...options }) => {
		//? get AUTH_TOKEN from localStorage or cookie
		// since we are storing access_token under different aliases for different users we must check which one is available

		let AUTH_TOKEN = 'no auth token';

		const AUTH_TOKEN_USER = localStorage.getItem('access_token_user');
		const AUTH_TOKEN_ADMIN = localStorage.getItem('access_token_admin');
		const AUTH_TOKEN_PANDIT = localStorage.getItem('access_token_pandit');

		if (AUTH_TOKEN_USER) {
			AUTH_TOKEN = AUTH_TOKEN_USER;
		} else if (AUTH_TOKEN_ADMIN) {
			AUTH_TOKEN = AUTH_TOKEN_ADMIN;
		} else if (AUTH_TOKEN_PANDIT) {
			AUTH_TOKEN = AUTH_TOKEN_PANDIT;
		} else {
			console.log(`Woops! I did not find any access token in localStorage`);
		}

		//? TODO: check if AUTH_TOKEN is valid or not if not then fetch new auth token using refresh_token
		axiosInstance.defaults.headers.common.Authorization = `Bearer ${AUTH_TOKEN}`;

		const onSuccess = (response: AxiosResponse) => Promise.resolve(response);
		const onError = (error: AxiosError) => Promise.reject(error);

		return axiosInstance(options).then(onSuccess).catch(onError);
	};

	return { axiosRequest, axiosInstance };
};
export default useAxios;
