import API_ENDPOINTS from 'constants/ApiEndpoints';
import { APP_ROUTES } from 'constants/AppRoutes';
import { useNavigate } from 'react-location';
import { useAppDispatch, useAppSelector } from 'store';
import { activeRoleReducer, errorReducer, successReducer } from 'store/app/appSlice';
import { saveUserProfileReducer, signInUserReducer, userDataInStore } from 'store/user/userSlice';
import useAxios from 'utils/useAxios';

const useAuthUser = () => {
	const { axiosRequest } = useAxios();

	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { isUserSignedIn } = useAppSelector(userDataInStore);

	// dispatch function that will set the user data in redux store and local storage while redirecting them to dashboard
	const dispatchFunc = (data?: {}) => {
		dispatch(
			signInUserReducer({
				...data,
			})
		);

		dispatch(activeRoleReducer({ role: 'user' }));
		navigate({ to: APP_ROUTES.DASHBOARD_USER });
	};

	// function that will sign out the user from client as well as server
	const signOutFunction = () => {
		// trigger sign out only when pandit is signed in
		if (!isUserSignedIn) return;
		axiosRequest({ url: API_ENDPOINTS.SIGN_OUT_USER, method: 'delete' })
			.then((response) => {
				if (response.status === 204) {
					dispatch(
						signInUserReducer({
							access_token: '',
						})
					);
					dispatch(saveUserProfileReducer({}));
					dispatch(activeRoleReducer({ role: '' }));
				}
			})
			.catch((error) => {
				console.log('🚀 ~ file: useAuthUser.ts ~ line 35 ~ signOutFunction ~ error', error);
			});
	};

	// * sign up function

	const signUpUser = (data: {}, setErrors: (fields: { [field: string]: string }) => void) =>
		axiosRequest({ url: API_ENDPOINTS.SIGN_UP_USER, method: 'post', data })
			.then((response) => {
				dispatchFunc(response.data);
			})
			.catch((error) => {
				// setting errors for formik to display
				setErrors({
					name: error.response.data.error?.message?.name,
					email: error.response.data.error?.message?.email,
					mobile: error.response.data.error?.message?.mobile,
					password: error.response.data.error?.message?.password,
				});

				// only store error to redux if status code is not 422 or else the code will break because of typescript
				if (error.response.data.error.status === 422) return;
				// setting errors for redux store to display on top of form. useful for displaying conflict error
				dispatch(errorReducer(error.response.data.error));
			});

	// * sign in function

	const signInUser = (data: {}, setErrors: (fields: { [field: string]: string }) => void) =>
		axiosRequest({ url: API_ENDPOINTS.SIGN_IN_USER, method: 'post', data })
			.then((response) => {
				dispatchFunc(response.data);
			})
			.catch((error) => {
				// setting errors for formik to display
				setErrors({
					emailOrMobile: error.response.data.error?.message?.emailOrMobile,
					password: error.response.data.error?.message?.password,
				});

				// only store error to redux if status code is not 422 or else the code will break because of typescript
				if (error.response.data.error.status === 422) return;
				// setting errors for redux store to display on top of form. useful for displaying not found error
				dispatch(errorReducer(error.response.data.error));
			});

	// * refresh token function

	const refreshJwtUser = () =>
		axiosRequest({ url: API_ENDPOINTS.REFRESH_JWT_USER, method: 'post' })
			.then((response) => {
				//  not using dispatch function declared on top as it has a redirect and we dont want user to be redirected to dashboard on each token refresh
				dispatch(
					signInUserReducer({
						...response.data,
					})
				);
			})
			.catch((error) => {
				console.log('🚀 ~ file: useAuthUser.ts ~ line 73 ~ useAuthUser ~ error', error);
				dispatch(errorReducer(error.response.data.error));
			});

	// * sign out function

	const signOutUser = () => {
		signOutFunction();
		navigate({ to: APP_ROUTES.HOME });
	};

	// * sign out function to use when logging in other two users

	const signOutUserWithoutRedirect = () => {
		signOutFunction();
	};

	// * password reset function

	const passwordResetUser = (
		data: {},
		setErrors: (fields: { [field: string]: string }) => void
	) => {
		axiosRequest({
			url: API_ENDPOINTS.CREATE_PASSWORD_RESET_LINK_USER,
			method: 'post',
			data,
		})
			.then((response) => {
				dispatch(successReducer(response.data));
			})
			.catch((error) => {
				setErrors({
					emailOrMobile: error.response.data.error?.message?.emailOrMobile,
				});
				if (error.response.data.error.status === 422) return;
				dispatch(errorReducer(error.response.data.error));
			});
	};

	// * password reset function

	const setNewPasswordUser = (
		data: {},
		setErrors: (fields: { [field: string]: string }) => void
	) => {
		axiosRequest({
			url: API_ENDPOINTS.SET_NEW_PASSWORD_USER,
			method: 'post',
			data,
		})
			.then((response) => {
				dispatch(successReducer(response.data));
			})
			.catch((error) => {
				setErrors({
					newPassword: error.response.data.error?.message?.newPassword,
				});
				if (error.response.data.error.status === 422) return;
				dispatch(errorReducer(error.response.data.error));
			});
	};

	return {
		signUpUser,
		signInUser,
		signOutUser,
		refreshJwtUser,
		passwordResetUser,
		setNewPasswordUser,
		signOutUserWithoutRedirect,
		isUserSignedIn,
	};
};

export default useAuthUser;
