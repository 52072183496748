import { Button, Typography } from '@mui/material';
import { Link } from 'react-location';

type LinkButtonProps = {
	link?: string;
	btnText: string;
};

const LinkButton = (props: LinkButtonProps) => {
	return (
		<Link to={props.link}>
			<Button size='small' disableFocusRipple>
				<Typography variant='body2'>{props.btnText}</Typography>
			</Button>
		</Link>
	);
};

export default LinkButton;
