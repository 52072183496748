import { Alert, AlertTitle, Button, LinearProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { MdEmail, MdPhone } from 'react-icons/md';
import { useAppSelector } from 'store';
import { appDataInStore } from 'store/app/appSlice';

type AlertPropTypes = {
	title?: string;
	function: () => void;
};

const AlertComponent = (props: AlertPropTypes) => {
	const [emailAlert, setEmailAlert] = useState({
		loading: false,
		btnText: 'Verify',
		alertTitle: 'Email verification required',
		alertText: 'Please verify your email address',
	});
	const [mobileAlert, setMobileAlert] = useState({
		loading: false,
		btnText: 'Verify',
		alertTitle: 'Mobile verification required',
		alertText: 'Please verify your mobile number',
	});
	const { emailVerificationStatus, mobileVerificationStatus } = useAppSelector(appDataInStore);

	useEffect(() => {
		switch (emailVerificationStatus) {
			case 'success':
				setEmailAlert({
					loading: false,
					alertTitle: 'Verification mail sent!',
					btnText: 'Try again ?',
					alertText:
						"Please check your email's inbox or spam folder for the same, if you didn't receive the email, please click on try again button.",
				});

				break;
			case 'warning':
				setEmailAlert({
					loading: true,
					alertTitle: 'Sending Verification Email!',
					btnText: '...',
					alertText: 'Please wait while we send the verification email',
				});
				break;

			case 'error':
				setEmailAlert({
					loading: false,
					btnText: 'Try again ?',
					alertTitle: 'Uh-oh an error occurred!',
					alertText:
						'Something went wrong while sending verification email, please try again later',
				});
				break;

			default:
				break;
		}
	}, [emailVerificationStatus]);

	useEffect(() => {
		switch (mobileVerificationStatus) {
			case 'success':
				setMobileAlert({
					loading: false,
					btnText: 'Try again ?',
					alertTitle: 'Verification text sent!',
					alertText:
						"Verification text sent! Please check your messages for verification link, if you didn't receive the message, please click on try again button.",
				});

				break;
			case 'warning':
				setMobileAlert({
					loading: true,
					btnText: '...',
					alertTitle: 'Sending Verification Message!',
					alertText: 'Please wait while we send the verification message',
				});
				break;

			case 'error':
				setMobileAlert({
					loading: false,
					btnText: 'Try again ?',
					alertTitle: 'Uh-oh an error occurred!',
					alertText:
						'Uh-oh something went wrong while sending verification message, please try again later',
				});
				break;

			default:
				break;
		}
	}, [mobileVerificationStatus]);

	return props.title === 'email' ? (
		<Alert
			severity={emailVerificationStatus}
			variant='outlined'
			icon={<MdEmail />}
			action={
				emailAlert.btnText.length > 3 && (
					<Button color={emailVerificationStatus} onClick={() => props?.function()}>
						{emailAlert.btnText} &nbsp;
					</Button>
				)
			}>
			{emailAlert.alertTitle.length > 0 && <AlertTitle>{emailAlert.alertTitle}</AlertTitle>}
			{emailAlert.alertText}
			{emailAlert.loading && <LinearProgress color={emailVerificationStatus} />}
		</Alert>
	) : (
		<Alert
			severity={mobileVerificationStatus}
			variant='outlined'
			icon={<MdPhone />}
			action={
				mobileAlert.btnText.length > 3 && (
					<Button color={mobileVerificationStatus} onClick={() => props?.function()}>
						{mobileAlert.btnText} &nbsp;
					</Button>
				)
			}>
			{mobileAlert.alertTitle.length > 0 && <AlertTitle>{mobileAlert.alertTitle}</AlertTitle>}
			{mobileAlert.alertText}
			{mobileAlert.loading && <LinearProgress color={mobileVerificationStatus} />}
		</Alert>
	);
};

export default AlertComponent;
