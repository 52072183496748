import { Divider, IconButton, Link, Typography } from '@mui/material';
import { MuiCard } from 'common';
import { ImYoutube2 } from 'react-icons/im';
import { MdOutlineFacebook } from 'react-icons/md';

const AboutUsCard = () => (
	<MuiCard header='About Us'>
		<Typography variant='subtitle1' component='p'>
			BrandName is the place where you can get Pandit ji at your door step by booking through your
			mobile online. The destination where you will get all your spiritual solution at one point. We
			plan puja according to the Vedas, our Priest and Astrologers are highly educated and well
			trained. We offer all types of puja , marriages, havan, astrology, kundli, vastu, jagran etc.
			We offer you best services at genuine cost.
		</Typography>

		<Divider />
		{/* social links  */}
		<Typography variant='h6'>Connect with us and never miss any update</Typography>
		<IconButton component={Link} href='mailto:email@email.com'>
			<MdOutlineFacebook />
		</IconButton>
		<IconButton component={Link} href='tel:1234567890'>
			<ImYoutube2 />
		</IconButton>
	</MuiCard>
);

export default AboutUsCard;
