import { MuiCard, TypewriterTypography } from 'common';

const WelcomeCard = () => (
	<MuiCard height={6.5} padding={0.5}>
		<TypewriterTypography fontSize={2.2} characterWidth={25}>
			Welcome to brand name.com
		</TypewriterTypography>

		<TypewriterTypography fontSize={1.2} characterWidth={34} animationDelay={3}>
			One stop shop for everything devotional.
		</TypewriterTypography>
	</MuiCard>
);

export default WelcomeCard;
