import { createSlice } from '@reduxjs/toolkit';
import { CommonInitialStateTypes, ProfileValueTypes } from 'store/common_types';
import { RootState } from '..';

type InitialStateTypes = CommonInitialStateTypes & {
	isUserSignedIn: boolean;
	user_profile: ProfileValueTypes;
};

const initialState: InitialStateTypes = {
	status: 'idle',
	isUserSignedIn: JSON.stringify(localStorage.getItem('access_token_user')).length > 15 ?? false,
	access_token: localStorage.getItem('access_token_user') ?? '',
	user_profile: JSON.parse(localStorage.getItem('user_profile') ?? '{}'),
};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {
		//? this functions will be triggered by dispatch() whenever auth does any operation related to authentication
		signInUserReducer: (state, action) => {
			// TODO: refactor this!
			state.access_token = action.payload.access_token;
			//! do not hardcode this value as it gets triggered by other user's auth actions , hard coding it will result into setting isSignedIn to true for all users
			state.isUserSignedIn = action.payload.access_token !== '';
			localStorage.setItem('access_token_user', action.payload.access_token);
		},

		saveUserProfileReducer: (state, action) => {
			state.user_profile = action.payload;
			localStorage.setItem('user_profile', JSON.stringify(action.payload));
		},
	},
});

// exporting reducer so that we can dispatch this in desired page(s) which then will trigger associated action with the reducer
export const { signInUserReducer, saveUserProfileReducer } = userSlice.actions;

// exporting the app data object for consumption by page(s)
export const userDataInStore = (state: RootState) => state.user;

// exporting the entire slice
export default userSlice.reducer;
