// Config file for changing or adding options to axios instance

export const AxiosInstanceConfig = {
	baseURL: 'http://localhost:6969/',
	headers: {
		// 'X-Custom-Header': 'foobar',
		accept: 'application/json',
		'content-type': 'application/json',
	},
	withCredentials: true,
	params: {
		// ID: 12345
	},
};
