import { DashboardComponent } from 'components';
import { useProfilePandit } from 'hooks';
import { useEffect } from 'react';

const DashboardPandit = () => {
	const { fetchProfilePandit } = useProfilePandit();

	useEffect(() => {
		fetchProfilePandit();
	}, []);

	return <DashboardComponent />;
};

export default DashboardPandit;
