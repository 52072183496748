// override material-ui styles
import { createTheme } from '@mui/material/styles';

export const overrides = createTheme({
	typography: {
		allVariants: {
			fontFamily: [
				'Ubuntu',
				'-apple-system',
				'BlinkMacSystemFont',
				'"Segoe UI"',
				'Roboto',
				'"Helvetica Neue"',
				'Arial',
				'sans-serif',
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(', '),
		},
	},
	// overriding material ui button styles
	components: {
		// Name of the component
		MuiButton: {
			styleOverrides: {
				// Name of the slot
				root: {
					// Some CSS
					textTransform: 'capitalize',
				},
			},
		},
		// overriding material ui card styles
		MuiCardContent: {
			styleOverrides: {
				root: {
					':last-child': { paddingBottom: '1rem' },
				},
			},
		},
		// overriding material ui listItem styles
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					minWidth: '45px',
				},
			},
		},
		// overriding material ui snackbar styles
		MuiSnackbar: {
			styleOverrides: {
				root: {
					top: '69px !important',
				},
			},
		},
		// overriding material ui form label styles
		MuiFormLabel: {
			styleOverrides: {
				root: { fontWeight: 500, textTransform: 'capitalize', letterSpacing: '.5px' },
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: { fontWeight: 500, textTransform: 'capitalize', letterSpacing: '.5px' },
			},
		},
	},
});
