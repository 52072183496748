import { createSlice } from '@reduxjs/toolkit';
import { CommonInitialStateTypes, ProfileValueTypes } from 'store/common_types';
import { RootState } from '..';

// extending InitialState declared in auth_types.ts file
type InitialStateTypes = CommonInitialStateTypes & {
	isAdminSignedIn: boolean;
	admin_profile: ProfileValueTypes;
};

const initialState: InitialStateTypes = {
	status: 'idle',
	isAdminSignedIn: JSON.stringify(localStorage.getItem('access_token_admin')).length > 15 ?? false,
	access_token: localStorage.getItem('access_token_admin') ?? '',
	admin_profile: JSON.parse(localStorage.getItem('admin_profile') ?? '{}'),
};

export const adminSlice = createSlice({
	name: 'admin',
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {
		//? this functions will be triggered by dispatch() whenever auth does any operation related to authentication
		signInAdminReducer: (state, action) => {
			//* TODO: refactor this!
			state.access_token = action.payload.access_token;
			//! do not hardcode this value as it gets triggered by other user's auth actions , hard coding it will result into setting isSignedIn to true for all users
			state.isAdminSignedIn = action.payload.access_token !== '';
			localStorage.setItem('access_token_admin', action.payload.access_token);
		},

		saveAdminProfileReducer: (state, action) => {
			state.admin_profile = action.payload;
			localStorage.setItem('admin_profile', JSON.stringify(action.payload));
		},
	},
});

// exporting reducer so that we can dispatch this in desired page(s) which then will trigger associated action with the reducer
export const { signInAdminReducer, saveAdminProfileReducer } = adminSlice.actions;

// exporting the app data object for consumption by page(s)
export const adminDataInStore = (state: RootState) => state.admin;

// exporting the entire slice
export default adminSlice.reducer;
