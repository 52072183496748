import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import adminSlice from './admin/adminSlice';
import appSlice from './app/appSlice';
import panditSlice from './pandit/panditSlice';
import userSlice from './user/userSlice';

export const store = configureStore({
	reducer: {
		app: appSlice,
		user: userSlice,
		admin: adminSlice,
		pandit: panditSlice,
	},
});

//! creating type for useSelector,useDispatch and state
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

//! Exporting useDispatch and useSelector with type-checking
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
