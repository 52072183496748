import { DashboardComponent } from 'components';
import { useProfileUser } from 'hooks';
import { useEffect } from 'react';

const DashboardUser = () => {
	const { fetchProfileUser } = useProfileUser();

	// Fetch user profile on component mount
	useEffect(() => {
		fetchProfileUser();
	}, []);

	return <DashboardComponent />;
};

export default DashboardUser;
