import { Button, CardMedia, Divider, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { MuiCard } from "common";

type PujaCardTypes = {
	image: string;
	title: string;
	description: string;
	priceIncludingSamagris: number;
	priceExcludingSamagris: number;
};

const PujaCard = ({ data }: { data: PujaCardTypes }) => {
	const {
		image,
		title,
		description,
		priceIncludingSamagris,
		priceExcludingSamagris,
	} = data;

	return (
		<MuiCard>
			<CardMedia component='img' alt={title} height='100%' image={image} />
			<Typography gutterBottom variant='h5' component='div'>
				{title}
			</Typography>
			<Typography variant='body2' color='text.secondary' gutterBottom>
				{description}
			</Typography>
			<FormControl component='fieldset'>
				<Divider>Please make a selection</Divider>

				<RadioGroup
					aria-label='prices'
					defaultValue={priceIncludingSamagris}
					name='radio-buttons-group'>
					<FormControlLabel
						value={priceExcludingSamagris}
						control={<Radio />}
						label={
							<Typography variant='h6'>Rs. {priceExcludingSamagris}</Typography>
						}
					/>
					<Typography variant='caption'>
						&nbsp;*Price includes pandit's fees only{" "}
					</Typography>

					<FormControlLabel
						value={priceIncludingSamagris}
						control={<Radio />}
						label={
							<Typography variant='h6'>Rs. {priceIncludingSamagris}</Typography>
						}
					/>
					<Typography variant='caption'>
						&nbsp;*Price includes pandit's fees + all samagris required for this
						puja
					</Typography>
				</RadioGroup>
			</FormControl>
			<Button variant='contained' fullWidth color='primary'>
				Book Now
			</Button>
		</MuiCard>
	);
};

export default PujaCard;
