import { InitialProfileValueTypes } from 'components/profile/profile_types';
import React from 'react';
import { ProfileValueTypes } from 'store/common_types';

type FunctionType = {
	profile_object_containing_client_info: ProfileValueTypes;
	setEmailToVerify: React.Dispatch<React.SetStateAction<string>>;
	setMobileToVerify: React.Dispatch<React.SetStateAction<string>>;
	setClientsName: React.Dispatch<React.SetStateAction<string>>;
	setIsClientsEmailVerified: React.Dispatch<React.SetStateAction<boolean>>;
	setIsClientsMobileVerified: React.Dispatch<React.SetStateAction<boolean>>;
	setInitialValues: React.Dispatch<React.SetStateAction<InitialProfileValueTypes>>;
};

export const profileFunction = ({
	profile_object_containing_client_info,
	setEmailToVerify,
	setMobileToVerify,
	setClientsName,

	setIsClientsEmailVerified,
	setIsClientsMobileVerified,
	setInitialValues,
}: FunctionType) => {
	const { name, gender, email, mobile, address, isMobileVerified, isEmailVerified } =
		profile_object_containing_client_info;
	setEmailToVerify(email);
	setMobileToVerify(mobile);
	setClientsName(name);
	setIsClientsEmailVerified(isEmailVerified);
	setIsClientsMobileVerified(isMobileVerified);
	setInitialValues({
		name,
		gender,
		email,
		mobile,
		address,
	});
};
