import { SignInComponent } from 'components';
import { APP_ROUTES } from 'constants/AppRoutes';

const SignInPandit = () => (
	<SignInComponent
		resetPasswordLink={APP_ROUTES.CREATE_PASSWORD_RESET_LINK_PANDIT}
		dashboardLink={APP_ROUTES.DASHBOARD_PANDIT}
		signUpLink={APP_ROUTES.SIGN_UP_PANDIT}
	/>
);

export default SignInPandit;
