import { BiLogInCircle } from 'react-icons/bi';
import { HiOutlineUserGroup } from 'react-icons/hi';
import { MdOutlineFireplace, MdOutlineHome, MdOutlineSpaceDashboard } from 'react-icons/md';
import { RiGalleryLine, RiPencilLine } from 'react-icons/ri';
import { useAppSelector } from 'store';
import { userDataInStore } from 'store/user/userSlice';

const useNavLinks = () => {
	const { isUserSignedIn } = useAppSelector(userDataInStore);
	const NAV_LINKS = [
		{
			key: 'key1',
			title: 'Home',
			path: '/',
			icon: MdOutlineHome,
		},
		{
			key: 'key2',
			title: 'Puja',
			path: '/puja',
			icon: MdOutlineFireplace,
		},
		{
			key: 'key3',
			title: 'Blog',
			path: '/blog',
			icon: RiPencilLine,
		},
		{
			key: 'key4',
			title: 'Members',
			path: '/members',
			icon: HiOutlineUserGroup,
		},
		{
			key: 'key5',
			title: 'Gallery',
			path: '/gallery',
			icon: RiGalleryLine,
		},
		{
			key: 'key6',
			title: isUserSignedIn ? 'Dashboard' : 'Sign In',
			path: isUserSignedIn ? '/dashboard' : '/sign-in',
			icon: isUserSignedIn ? MdOutlineSpaceDashboard : BiLogInCircle,
		},
	];
	return { NAV_LINKS };
};

export default useNavLinks;
