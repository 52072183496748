// * hook to verify email and mobile number of all the user types

import API_ENDPOINTS from 'constants/ApiEndpoints';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import {
	appDataInStore,
	emailVerificationStatusReducer,
	mobileVerificationStatusReducer,
} from 'store/app/appSlice';
import useAxios from 'utils/useAxios';

const useVerification = () => {
	const { axiosRequest } = useAxios();
	const dispatch = useAppDispatch();
	const [emailVerificationEndpoint, setEmailVerificationEndpoint] = useState('');
	const [mobileVerificationEndpoint, setMobileVerificationEndpoint] = useState('');

	const { active_role } = useAppSelector(appDataInStore);

	/* setting endpoint for email and mobile verification based on user type */
	useEffect(() => {
		switch (active_role) {
			case 'admin':
				setEmailVerificationEndpoint(API_ENDPOINTS.CREATE_EMAIL_VERIFICATION_LINK_ADMIN);
				setMobileVerificationEndpoint(API_ENDPOINTS.CREATE_MOBILE_VERIFICATION_LINK_ADMIN);
				break;
			case 'pandit':
				setEmailVerificationEndpoint(API_ENDPOINTS.CREATE_EMAIL_VERIFICATION_LINK_PANDIT);
				setMobileVerificationEndpoint(API_ENDPOINTS.CREATE_MOBILE_VERIFICATION_LINK_PANDIT);
				break;
			default:
				setEmailVerificationEndpoint(API_ENDPOINTS.CREATE_EMAIL_VERIFICATION_LINK_USER);
				setMobileVerificationEndpoint(API_ENDPOINTS.CREATE_MOBILE_VERIFICATION_LINK_USER);
				break;
		}
	}, [active_role]);

	/* function that will trigger email with verification link  */
	const sendVerificationEmail = (email: {}) => {
		dispatch(emailVerificationStatusReducer('warning'));
		axiosRequest({ url: emailVerificationEndpoint, method: 'post', data: email })
			.then(() => {
				dispatch(emailVerificationStatusReducer('success'));
			})
			.catch((error) => {
				if (error.response.data.status === 401 && error.response.data.message === 'jwt expired') {
					// since it's a jwt expired error, we need to refresh the token which will be handled by axios interceptor, so we are setting status to success
					dispatch(emailVerificationStatusReducer('success'));
				}

				dispatch(emailVerificationStatusReducer('error'));
			});
	};

	/* function that will trigger text message with verification link  */
	const sendVerificationTextMessage = (mobile: {}) => {
		dispatch(mobileVerificationStatusReducer('warning'));

		axiosRequest({
			url: mobileVerificationEndpoint,
			method: 'post',
			data: mobile,
		})
			.then(() => {
				dispatch(mobileVerificationStatusReducer('success'));
			})
			.catch((error) => {
				if (error.response.status === 401) {
					// since it's a jwt expired error, we need to refresh the token which will be handled by axios interceptor, so we are setting status to success
					dispatch(mobileVerificationStatusReducer('success'));
				}
				dispatch(mobileVerificationStatusReducer('error'));
			});
	};

	return { sendVerificationEmail, sendVerificationTextMessage };
};

export default useVerification;
