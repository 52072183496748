// import { useAppSelector, useAppDispatch } from 'store';
// import { signInAdminReducer, adminDataInStore } from 'store/admin/adminSlice';
import API_ENDPOINTS from 'constants/ApiEndpoints';
import { useAppDispatch } from 'store';
import { saveAdminProfileReducer } from 'store/admin/adminSlice';
import useAxios from 'utils/useAxios';

const useProfileAdmin = () => {
	const { axiosRequest } = useAxios();

	const dispatch = useAppDispatch();

	/* function to fetch admin's profile  */
	const fetchProfileAdmin = () =>
		axiosRequest({ url: API_ENDPOINTS.PROFILE_ADMIN, method: 'get' })
			.then((response) => {
				dispatch(saveAdminProfileReducer(response.data.profile));
			})
			.catch((error) => {
				console.log('error', error.response);
			});

	/* function to fetch admin's profile  */
	const updateProfileAdmin = (data: {}, setErrors: (fields: { [field: string]: string }) => void) =>
		axiosRequest({ url: API_ENDPOINTS.PROFILE_ADMIN, method: 'patch', data })
			.then((response) => {
				// if update happens successfully, fetch the updated profile
				if (response.status === 204) {
					fetchProfileAdmin();
				}
			})
			.catch((error) => {
				// if update fails, set the errors
				if (error.response.status === 422) {
					setErrors({
						name: error.response.data.error?.message?.name,
						gender: error.response.data.error?.message?.gender,
						address: error.response.data.error?.message?.address,
						email: error.response.data.error?.message?.email,
						mobile: error.response.data.error?.message?.mobile,
					});
				} else if (error.response.status === 409) {
					if (error.response.data.error.message.includes('email')) {
						setErrors({
							email: error.response.data.error?.message,
						});
					} else if (error.response.data.error.message.includes('mobile')) {
						setErrors({
							mobile: error.response.data.error?.message,
						});
					}
				}
			});

	/* function to fetch admin's profile  */
	const deleteProfileAdmin = () =>
		axiosRequest({ url: API_ENDPOINTS.PROFILE_ADMIN, method: 'delete' })
			.then((response) => {
				console.log('🚀 ~ file: useProfileAdmin.ts ~ line 35 ~ .then ~ response', response);
			})
			.catch((error) => {
				console.log('error', error.response);
			});

	return { fetchProfileAdmin, updateProfileAdmin, deleteProfileAdmin };
};

export default useProfileAdmin;
