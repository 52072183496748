import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';

type InitialState = {
	status: 'idle' | 'loading' | 'failed';
	isDarkModeActive: boolean;
	active_role: string;
	error: {
		status: number;
		message: string;
	};
	success: {
		status: number;
		message: string;
	};
	emailVerificationStatus: 'success' | 'info' | 'warning' | 'error';
	mobileVerificationStatus: 'success' | 'info' | 'warning' | 'error';
};

const initialState: InitialState = {
	status: 'idle',
	isDarkModeActive: localStorage.getItem('isDarkModeActive') === 'true',
	active_role: localStorage.getItem('active_role') ?? '',
	error: {
		status: 0,
		message: 'there are no errors!',
	},
	success: { status: 0, message: 'there are no success!' },
	emailVerificationStatus: 'info',
	mobileVerificationStatus: 'info',
};

export const appSlice = createSlice({
	name: 'app',
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {
		toggleDarkModeReducer: (state) => {
			state.isDarkModeActive = !state.isDarkModeActive;
			localStorage.setItem('isDarkModeActive', state.isDarkModeActive.toString());
		},

		emailVerificationStatusReducer: (state, action) => {
			state.emailVerificationStatus = action.payload;
		},

		mobileVerificationStatusReducer: (state, action) => {
			state.mobileVerificationStatus = action.payload;
		},

		activeRoleReducer: (state, action) => {
			state.active_role = action.payload.role;
			localStorage.setItem('active_role', action.payload.role);
		},

		errorReducer: (state, action) => {
			state.error = action.payload;
		},

		clearErrorReducer: (state) => {
			state.error = {
				status: 0,
				message: 'there are no errors!',
			};
		},

		successReducer: (state, action) => {
			state.success = action.payload;
		},

		clearSuccessReducer: (state) => {
			state.success = {
				status: 0,
				message: 'there are no success!',
			};
		},
	},
});

// exporting reducer so that we can dispatch this in desired page(s) which then will trigger associated action with the reducer
export const {
	toggleDarkModeReducer,
	activeRoleReducer,
	errorReducer,
	clearErrorReducer,
	emailVerificationStatusReducer,
	mobileVerificationStatusReducer,
	successReducer,
	clearSuccessReducer,
} = appSlice.actions;

// exporting the app data object for consumption by page(s)
export const appDataInStore = (state: RootState) => state.app;

// exporting the entire slice
export default appSlice.reducer;
