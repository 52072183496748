import { ThemeProvider } from '@mui/material/styles';
import { Navigation } from 'components';
import { Outlet, ReactLocation, Router } from 'react-location';
import { ReactLocationDevtools } from 'react-location-devtools';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import AppRoutes from 'routes/AppRoutes';
import { useAppSelector } from 'store';
import { appDataInStore } from 'store/app/appSlice';
import { darkTheme, lightTheme } from 'theme/Theme';

const location = new ReactLocation();

const queryClient = new QueryClient();

const App = () => {
	const { appRoutes } = AppRoutes();
	const { isDarkModeActive } = useAppSelector(appDataInStore);

	return (
		<Router location={location} routes={appRoutes}>
			<QueryClientProvider client={queryClient}>
				<ThemeProvider theme={isDarkModeActive ? darkTheme : lightTheme}>
					<Navigation>
						{/* Outlet is window where all the components will get render by react-location */}
						<Outlet />
					</Navigation>
				</ThemeProvider>{' '}
				<ReactLocationDevtools position='bottom-right' />
				<ReactQueryDevtools
				// initialIsOpen //! uncomment this to keep react-query-dev-tools open by default
				/>
			</QueryClientProvider>
		</Router>
	);
};

export default App;
