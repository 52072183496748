import { Button, Container, Divider, Grid, Typography } from '@mui/material';
import { FormikControl, LinkButton } from 'common';
import { APP_ROUTES } from 'constants/AppRoutes';
import { Form, Formik, FormikHelpers } from 'formik';
import { useAuthAdmin, useAuthPandit, useAuthUser } from 'hooks';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-location';
import { PasswordResetLinkCreationValidationSchema } from 'utils/FormValidationSchemas';
import {
	InitialPasswordResetValueTypes,
	PasswordResetLinkCreatorComponentPropTypes,
} from './auth_types';

const PasswordResetLinkCreatorComponent = (props: PasswordResetLinkCreatorComponentPropTypes) => {
	const { signInLink } = props;
	const location = useLocation(); // useHistory alternative in react-location
	const { passwordResetUser } = useAuthUser();
	const { passwordResetAdmin } = useAuthAdmin();
	const { passwordResetPandit } = useAuthPandit();
	/* setting initial values for formik */

	const initialValues = {
		emailOrMobile: '',
	};

	const [currentLocation, setCurrentLocation] = useState('');

	// before mounting set the pathname to currentLocation variable to for submit fn to decide which switch block to run
	useEffect(() => {
		setCurrentLocation(location.current.pathname);
	}, [location]);

	const onSubmit = (
		values: InitialPasswordResetValueTypes,
		{ setErrors }: FormikHelpers<InitialPasswordResetValueTypes>
	) => {
		console.log('onSubmit ~ values', values);
		// deciding which auth function to hit based on location at which user is at
		switch (currentLocation) {
			case APP_ROUTES.CREATE_PASSWORD_RESET_LINK_ADMIN:
				passwordResetAdmin({ ...values }, setErrors);
				break;

			case APP_ROUTES.CREATE_PASSWORD_RESET_LINK_PANDIT:
				passwordResetPandit({ ...values }, setErrors);
				break;

			default:
				console.log('password', values);
				passwordResetUser({ ...values }, setErrors);
				break;
		}
	};
	return (
		<Container maxWidth='xs' sx={{ mt: 5 }}>
			<Grid container justifyContent='space-between' alignItems='center' spacing={1}>
				<Grid item>
					<Typography variant='h5'>Password reset</Typography>
				</Grid>
				<Grid item>
					{/* checking if user is on login page or register page based on which show respective message */}
					{signInLink && <LinkButton link={signInLink} btnText='remember password?' />}
				</Grid>
			</Grid>
			<Divider />

			{/* Show errors if user enters wrong credentials or account does not exists */}
			{/* {error.status === 401 || error.status === 404 ? (
				<Alert
					severity='error'
					sx={{ mt: 1 }}
					variant='outlined'
					onClose={() => dispatch(clearErrorReducer())}>
					<AlertTitle>{alertTitle} </AlertTitle>
					{error.message}
				</Alert>
			) : null} */}

			<Formik
				initialValues={initialValues}
				validationSchema={PasswordResetLinkCreationValidationSchema}
				onSubmit={onSubmit}>
				{() => (
					<Form>
						<FormikControl control='muiInput' name='emailOrMobile' />
						<Button variant='outlined' color='primary' type='submit' fullWidth>
							Submit
						</Button>
					</Form>
				)}
			</Formik>
		</Container>
	);
};

export default PasswordResetLinkCreatorComponent;
