import {
	Alert,
	AlertTitle,
	Button,
	Checkbox,
	Container,
	Divider,
	FormControlLabel,
	Grid,
	Typography,
} from '@mui/material';
import { FormikControl, LinkButton } from 'common';
import { Form, Formik, FormikHelpers } from 'formik';
import useAuthAdmin from 'hooks/admin/useAuthAdmin';
import useAuthPandit from 'hooks/pandit/useAuthPandit';
import useAuthUser from 'hooks/user/useAuthUser';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-location';
import { useAppDispatch, useAppSelector } from 'store';
import { appDataInStore, clearErrorReducer } from 'store/app/appSlice';
import { SignUpValidationSchema } from 'utils/FormValidationSchemas';
import { InitialSignUpValueTypes, SignUpComponentPropTypes } from './auth_types';

const SignUpComponent = (props: SignUpComponentPropTypes) => {
	const location = useLocation(); // useHistory alternative in react-location
	const { signInLink, signUpLink } = props;

	const { error } = useAppSelector(appDataInStore);
	const dispatch = useAppDispatch();
	/* setting initial values for formik */
	const initialValues = {
		name: '',
		email: '',
		mobile: '',
		password: '',
	};

	const [currentLocation, setCurrentLocation] = useState('');

	// before mounting set the pathname to currentLocation variable to for submit fn to decide which switch block to run
	useEffect(() => {
		setCurrentLocation(location.current.pathname);
	}, [location]);

	// cleanup useEffect which will run every time the component unmounts to clear the errors from redux store
	useEffect(
		() => () => {
			dispatch(clearErrorReducer());
		},
		[dispatch]
	);

	// destructuring auth functions from //*useAuthAdmin hook
	const { signUpAdmin, signOutAdminWithoutRedirect } = useAuthAdmin();

	// destructuring auth functions from //*useAuthUser hook
	const { signUpUser, signOutUserWithoutRedirect } = useAuthUser();

	// destructuring auth functions from //*useAuthPandit hook
	const { signUpPandit, signOutPanditWithoutRedirect } = useAuthPandit();

	const [showPassword, setShowPassword] = useState(false);

	/* handling form submission of formik */

	const onSubmit = (
		values: InitialSignUpValueTypes,
		{ setErrors }: FormikHelpers<InitialSignUpValueTypes>
	) => {
		// switching auth function to hit based on location at which user is at
		switch (currentLocation) {
			case '/sign-up-admin':
				signOutPanditWithoutRedirect();
				signOutUserWithoutRedirect();
				signUpAdmin({ ...values }, setErrors);
				break;

			case '/sign-up-pandit':
				signOutAdminWithoutRedirect();
				signOutUserWithoutRedirect();
				signUpPandit({ ...values }, setErrors);
				break;

			default:
				signOutAdminWithoutRedirect();
				signOutPanditWithoutRedirect();
				signUpUser({ ...values }, setErrors);

				break;
		}
	};

	return (
		<Container maxWidth='xs' sx={{ mt: 5 }}>
			<Grid container justifyContent='space-between' alignItems='center'>
				<Grid item>
					<Typography variant='h5'>Sign Up</Typography>{' '}
				</Grid>
				<Grid item>
					{/* checking if user is on login page or register page based on which show respective message */}
					{signUpLink ? (
						<LinkButton link={signUpLink} btnText='need an account?' />
					) : (
						<LinkButton link={signInLink} btnText='got an account?' />
					)}
				</Grid>
			</Grid>
			<Divider />

			{/* Show errors if user enters wrong credentials or account does not exists */}
			{error.status === 409 ? (
				<Alert
					severity='error'
					sx={{ mt: 1 }}
					variant='outlined'
					onClose={() => dispatch(clearErrorReducer())}>
					<AlertTitle>Conflict</AlertTitle>
					{error.message}
				</Alert>
			) : null}

			<Formik
				initialValues={initialValues}
				validationSchema={SignUpValidationSchema}
				onSubmit={onSubmit}>
				{() => (
					<Form>
						<FormikControl control='muiInput' name='name' />

						<FormikControl control='muiInput' name='email' />

						<FormikControl control='muiInput' name='mobile' />

						<FormikControl
							control='muiInput'
							type={showPassword ? 'text' : 'password'}
							name='password'
						/>

						<FormControlLabel
							value='start'
							control={
								<Checkbox
									size='small'
									color='success'
									checked={showPassword}
									onChange={(e) => setShowPassword(!showPassword)}
								/>
							}
							label={<Typography variant='body2'>Show Password</Typography>}
							labelPlacement='end'
						/>

						<Button variant='outlined' color='primary' type='submit' fullWidth>
							Submit
						</Button>
					</Form>
				)}
			</Formik>
		</Container>
	);
};

export default SignUpComponent;
