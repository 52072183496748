import { HiOutlineUserGroup } from 'react-icons/hi';
import { MdOutlineFireplace, MdOutlineHome } from 'react-icons/md';
import { RiGalleryLine, RiPencilLine } from 'react-icons/ri';

export const CATALOGUE = [
	{
		key: 0,
		title: 'Pujas',
		icon: MdOutlineHome,
	},
	{
		key: 1,
		title: 'Paaths',
		icon: MdOutlineFireplace,
	},
	{
		key: 2,
		title: 'Jaaps',
		icon: RiPencilLine,
	},
	{
		key: 3,
		title: 'Havans',
		icon: HiOutlineUserGroup,
	},
	{
		key: 4,
		title: 'Festival Puja',
		icon: RiGalleryLine,
	},
];
