export const PAATHS = [
	{
		key: 0,
		title: 'Paath One',
		description: 'la lal ala bla bla bla la lal ala bla bla bla la lal ala bla bla bla',
		priceExcludingSamagris: 400,
		priceIncludingSamagris: 500,
		image: 'https://avatars.githubusercontent.com/u/61915612?s=88&v=4',
	},
	{
		key: 1,
		title: 'Paath Two',
		description: 'la lal ala bla bla bla la lal ala bla bla bla la lal ala bla bla bla',
		priceExcludingSamagris: 600,
		priceIncludingSamagris: 700,
		image: 'https://avatars.githubusercontent.com/u/61915612?s=88&v=4',
	},
	{
		key: 2,
		title: 'Paath Three',
		description: 'la lal ala bla bla bla la lal ala bla bla bla la lal ala bla bla bla',
		priceExcludingSamagris: 800,
		priceIncludingSamagris: 900,
		image: 'https://avatars.githubusercontent.com/u/61915612?s=88&v=4',
	},
	{
		key: 3,
		title: 'Paath Four',
		description: 'la lal ala bla bla bla la lal ala bla bla bla la lal ala bla bla bla',
		priceExcludingSamagris: 1000,
		priceIncludingSamagris: 1100,
		image: 'https://avatars.githubusercontent.com/u/61915612?s=88&v=4',
	},
	{
		key: 4,
		title: 'Paaths Five',
		description: 'la lal ala bla bla bla la lal ala bla bla bla la lal ala bla bla bla',
		priceExcludingSamagris: 1200,
		priceIncludingSamagris: 1300,
		image: 'https://avatars.githubusercontent.com/u/61915612?s=88&v=4',
	},
];
