import {
	Box,
	CssBaseline,
	Divider,
	Grid,
	// Hidden,
	IconButton,
	Link,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Paper,
	SvgIcon,
	Toolbar,
	Typography,
} from '@mui/material';
import { BrandIcon } from 'assets';
import useAuthAdmin from 'hooks/admin/useAuthAdmin';
import useAuthPandit from 'hooks/pandit/useAuthPandit';
import useNavLinks from 'hooks/useNavLinks';
import useAuthUser from 'hooks/user/useAuthUser';
import { useWindowSize } from 'hooks/useWindowSize';
import React, { useState } from 'react';
import { BiLogOutCircle } from 'react-icons/bi';
import { FiPhoneCall } from 'react-icons/fi';
import { ImSun } from 'react-icons/im';
import { MdMenu, MdMenuOpen, MdOutlineEmail, MdOutlineLocationOn } from 'react-icons/md';
import { RiMoonClearLine } from 'react-icons/ri';
import { useNavigate } from 'react-location';
import { useAppDispatch, useAppSelector } from 'store';
import { appDataInStore, toggleDarkModeReducer } from 'store/app/appSlice';
import { AppBar, Drawer, DrawerHeader } from './NavigationComponents';

type NavigationTypes = {
	children: React.ReactNode;
};

const Navigation = ({ children }: NavigationTypes) => {
	const { signOutUser } = useAuthUser();
	const { signOutAdmin } = useAuthAdmin();
	const { signOutPandit } = useAuthPandit();

	const { NAV_LINKS } = useNavLinks();
	const windowSize = useWindowSize();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { active_role } = useAppSelector(appDataInStore);

	const { isDarkModeActive } = useAppSelector(appDataInStore);

	const [open, setOpen] = useState(false);

	const toggleDrawer = () => {
		setOpen(!open);
	};

	// collapse the drawer when the screen is small
	const handleLinkClick = (path: string) => {
		navigate({ to: path });
		if (windowSize.width < 600) {
			setOpen(false);
		}
	};

	// sign-out function
	const signOutFunction = () => {
		signOutUser();
		signOutAdmin();
		signOutPandit();
	};

	return (
		<Box sx={{ display: 'flex' }}>
			<CssBaseline />
			<AppBar
				position='fixed'
				color='inherit'
				// style={{ transition: "all 1s linear" }}
			>
				<Toolbar>
					<Grid container justifyContent='space-between' alignItems='center'>
						<Grid item container xs={9} sm={10} alignItems='center'>
							<IconButton
								sx={{ p: 0.5 }} //for aligning menu icon with list item icons TODO: align menu icon with list items for mobile devices
								color='inherit'
								aria-label='open drawer'
								onClick={toggleDrawer}
								edge='start'>
								{open ? <MdMenuOpen /> : <MdMenu />}
							</IconButton>
							<BrandIcon />
							{/* <Hidden smDown> */}
							<Typography variant='h6' noWrap component='div'>
								Brand Name
							</Typography>
							{/* </Hidden> */}
						</Grid>
						<Grid item container xs={3} sm={2} alignItems='center' justifyContent={'flex-end'}>
							{/* dark mode toggle switch */}
							<IconButton onClick={() => dispatch(toggleDarkModeReducer())} title='toggle theme'>
								{isDarkModeActive ? <RiMoonClearLine /> : <ImSun />}
							</IconButton>

							{/* sign-out */}
							{active_role.length > 0 && (
								<IconButton onClick={signOutFunction} title='sign-out'>
									<BiLogOutCircle />
								</IconButton>
							)}
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			<Drawer variant='permanent' open={open}>
				<DrawerHeader />

				<List>
					{NAV_LINKS?.map((nav) => (
						<ListItem button key={nav.key} onClick={() => handleLinkClick(nav.path)}>
							<ListItemIcon>
								{/* TODO: pick one of the two formats for displaying svg icons */}
								<SvgIcon component={nav.icon} />
								{/* <nav.icon /> */}
							</ListItemIcon>
							<ListItemText primary={nav.title} />
						</ListItem>
					))}
				</List>

				<List sx={{ marginTop: 'auto' }}>
					{open && <Divider>Reach us</Divider>}
					<Box component={ListItem} display={'flex'} flexDirection={open ? 'row' : 'column'}>
						<IconButton component={Link} href='mailto:email@email.com'>
							<MdOutlineEmail />
						</IconButton>
						<IconButton component={Link} href='tel:1234567890'>
							<FiPhoneCall />
						</IconButton>
						<IconButton
							component={Link}
							target='_blank'
							href='http://maps.google.com/?q=1200 Pennsylvania Ave SE, Washington, District of Columbia, 20003'>
							<MdOutlineLocationOn />
						</IconButton>
					</Box>
				</List>
			</Drawer>
			<Paper
				component='main'
				sx={{
					flexGrow: 1,
					p: 1,
					overflowX: 'hidden',
					height: '100vh',
					width: '100%',
					borderRadius: 0,
					// transition: "all 1s linear",
				}}>
				<DrawerHeader />
				{children}
			</Paper>
		</Box>
	);
};
export default Navigation;
