import axios, { AxiosInstance } from 'axios';
import API_ENDPOINTS from 'constants/ApiEndpoints';

export const AxiosResponseInterceptor = (axiosInstance: AxiosInstance) => {
	// * Add a response interceptor
	axiosInstance.interceptors.response.use(
		//// Any status codes that falls inside the range of 2xx cause this function to trigger
		(response) => response,

		//// Any status codes that falls outside the range of 2xx cause this function to trigger
		(error) => {
			if (
				error.response.data.error.status === 401 &&
				error.response.data.error.message === 'jwt expired'
			) {
				// check who is logged in and accordingly change endpoint
				axiosInstance({ url: API_ENDPOINTS.REFRESH_JWT_USER, method: 'post' })
					.then((response) => {
						// setting new access token to local storage for future usage
						localStorage.setItem('access_token_user', response.data.token.access_token);
						// updating previous request's auth header and continuing the request
						error.config.headers.Authorization = `Bearer ${response.data.token.access_token}`;
						return axios.request(error.config);
					})
					.catch((instanceError) => {
						return Promise.reject(instanceError);

						// console.log('instance error', instanceError.response);
						// logout user if any error occurs and ask them to sign in again
					});
			}
			// Do something with response error
			return Promise.reject(error);
		}
	);
};

export default AxiosResponseInterceptor;
