import { SignInComponent } from 'components';
import { APP_ROUTES } from 'constants/AppRoutes';

const SignInUser = () => (
	<SignInComponent
		dashboardLink={APP_ROUTES.DASHBOARD_USER}
		resetPasswordLink={APP_ROUTES.CREATE_PASSWORD_RESET_LINK_USER}
		signUpLink={APP_ROUTES.SIGN_UP_USER}
	/>
);

export default SignInUser;
