import { APP_ROUTES } from 'constants/AppRoutes';
import {
	DashboardAdmin,
	PasswordResetAdmin,
	SetNewPasswordAdmin,
	SignInAdmin,
	SignUpAdmin,
} from 'pages/adminPages';
import BlogPage from 'pages/blogPage/BlogPage';
import GalleryPage from 'pages/galleryPage/GalleryPage';
import HomePage from 'pages/homePage/HomePage';
import MembersPage from 'pages/membersPage/MembersPage';
import {
	DashboardPandit,
	PasswordResetPandit,
	SetNewPasswordPandit,
	SignInPandit,
	SignUpPandit,
} from 'pages/panditPages';
import PujaPage from 'pages/pujaPage/PujaPage';
import {
	DashboardUser,
	PasswordResetUser,
	SetNewPasswordUser,
	SignInUser,
	SignUpUser,
} from 'pages/userPages';
import { Navigate, Route } from 'react-location';
import { useAppSelector } from 'store';
import { adminDataInStore } from 'store/admin/adminSlice';
import { panditDataInStore } from 'store/pandit/panditSlice';
import { userDataInStore } from 'store/user/userSlice';

const AppRoutes = () => {
	const { isUserSignedIn } = useAppSelector(userDataInStore);
	const { isPanditSignedIn } = useAppSelector(panditDataInStore);
	const { isAdminSignedIn } = useAppSelector(adminDataInStore);

	const appRoutes: Route[] = [
		{
			path: APP_ROUTES.HOME,
			element: <HomePage />,
		},
		{
			path: APP_ROUTES.PUJA,
			element: <PujaPage />,
		},
		{ path: APP_ROUTES.GALLERY, element: <GalleryPage /> },
		{ path: APP_ROUTES.BLOG, element: <BlogPage /> },
		{ path: APP_ROUTES.MEMBERS, element: <MembersPage /> },
		//* for admin
		{
			path: APP_ROUTES.SIGN_UP_ADMIN,
			element: !isAdminSignedIn ? <SignUpAdmin /> : <Navigate to={APP_ROUTES.DASHBOARD_ADMIN} />,
		},
		{
			path: APP_ROUTES.SIGN_IN_ADMIN,
			element: !isAdminSignedIn ? <SignInAdmin /> : <Navigate to={APP_ROUTES.DASHBOARD_ADMIN} />,
		},
		{
			path: APP_ROUTES.DASHBOARD_ADMIN,
			element: isAdminSignedIn ? <DashboardAdmin /> : <Navigate to={APP_ROUTES.SIGN_IN_ADMIN} />,
		},
		{
			path: APP_ROUTES.CREATE_PASSWORD_RESET_LINK_ADMIN,
			element: isAdminSignedIn ? <DashboardAdmin /> : <PasswordResetAdmin />,
		},
		{
			path: APP_ROUTES.SET_NEW_PASSWORD_ADMIN,
			element: isUserSignedIn ? <DashboardAdmin /> : <SetNewPasswordAdmin />,
		},

		//* for pandit
		{
			path: APP_ROUTES.SIGN_UP_PANDIT,
			element: !isPanditSignedIn ? <SignUpPandit /> : <Navigate to={APP_ROUTES.DASHBOARD_PANDIT} />,
		},
		{
			path: APP_ROUTES.SIGN_IN_PANDIT,
			element: !isPanditSignedIn ? <SignInPandit /> : <Navigate to={APP_ROUTES.DASHBOARD_PANDIT} />,
		},
		{
			path: APP_ROUTES.DASHBOARD_PANDIT,
			element: isPanditSignedIn ? <DashboardPandit /> : <Navigate to={APP_ROUTES.SIGN_IN_PANDIT} />,
		},
		{
			path: APP_ROUTES.CREATE_PASSWORD_RESET_LINK_PANDIT,
			element: isPanditSignedIn ? <DashboardPandit /> : <PasswordResetPandit />,
		},
		{
			path: APP_ROUTES.SET_NEW_PASSWORD_PANDIT,
			element: isUserSignedIn ? <DashboardPandit /> : <SetNewPasswordPandit />,
		},

		//* for user
		{
			path: APP_ROUTES.SIGN_UP_USER,
			element: !isUserSignedIn ? <SignUpUser /> : <Navigate to={APP_ROUTES.DASHBOARD_USER} />,
		},
		{
			path: APP_ROUTES.SIGN_IN_USER,
			element: !isUserSignedIn ? <SignInUser /> : <Navigate to={APP_ROUTES.DASHBOARD_USER} />,
		},
		{
			path: APP_ROUTES.DASHBOARD_USER,
			element: isUserSignedIn ? <DashboardUser /> : <Navigate to={APP_ROUTES.SIGN_IN_USER} />,
		},
		{
			path: APP_ROUTES.CREATE_PASSWORD_RESET_LINK_USER,
			element: isUserSignedIn ? <DashboardUser /> : <PasswordResetUser />,
		},
		{
			path: APP_ROUTES.SET_NEW_PASSWORD_USER,
			element: isUserSignedIn ? <DashboardUser /> : <SetNewPasswordUser />,
		},

		{
			element: <Navigate to='/' />,
		},
	];
	return { appRoutes };
};
export default AppRoutes;
