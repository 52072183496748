// ! central location for all the routes in the application
export const APP_ROUTES = {
	HOME: '/',
	PUJA: '/puja',
	BLOG: '/blog',
	MEMBERS: '/members',
	GALLERY: '/gallery',
	//! admin links
	DASHBOARD_ADMIN: '/dashboard-admin',
	SIGN_IN_ADMIN: '/sign-in-admin',
	SIGN_UP_ADMIN: '/sign-up-admin',
	CREATE_PASSWORD_RESET_LINK_ADMIN: '/create-password-reset-link-admin',
	SET_NEW_PASSWORD_ADMIN: '/set-new-password-admin',
	//! pandit links
	DASHBOARD_PANDIT: '/dashboard-pandit',
	SIGN_IN_PANDIT: '/sign-in-pandit',
	SIGN_UP_PANDIT: '/sign-up-pandit',
	CREATE_PASSWORD_RESET_LINK_PANDIT: '/create-password-reset-link-pandit',
	SET_NEW_PASSWORD_PANDIT: '/set-new-password-pandit',
	//! admin links
	DASHBOARD_USER: '/dashboard',
	SIGN_IN_USER: '/sign-in',
	SIGN_UP_USER: '/sign-up',
	CREATE_PASSWORD_RESET_LINK_USER: '/create-password-reset-link',
	SET_NEW_PASSWORD_USER: '/set-new-password-user/:token',
};
