import { SignUpComponent } from 'components';
import { APP_ROUTES } from 'constants/AppRoutes';

const SignUpAdmin = () => (
	<SignUpComponent
		dashboardLink={APP_ROUTES.DASHBOARD_ADMIN}
		signInLink={APP_ROUTES.SIGN_IN_ADMIN}
	/>
);

export default SignUpAdmin;
