export const PUJAS = [
	{
		key: 0,
		title: 'Puja One',
		description: 'la lal ala bla bla bla la lal ala bla bla bla la lal ala bla bla bla',
		priceExcludingSamagris: 400,
		priceIncludingSamagris: 500,
		image: 'https://avatars.githubusercontent.com/u/3167791?s=88&v=4',
	},
	{
		key: 1,
		title: 'Puja Two',
		description: 'la lal ala bla bla bla la lal ala bla bla bla la lal ala bla bla bla',
		priceExcludingSamagris: 600,
		priceIncludingSamagris: 700,

		image: 'https://avatars.githubusercontent.com/u/3167791?s=88&v=4',
	},
	{
		key: 2,
		title: 'Puja Three',
		description: 'la lal ala bla bla bla la lal ala bla bla bla la lal ala bla bla bla',
		priceExcludingSamagris: 800,
		priceIncludingSamagris: 900,
		image: 'https://avatars.githubusercontent.com/u/3167791?s=88&v=4',
	},
	{
		key: 3,
		title: 'Puja Four',
		description: 'la lal ala bla bla bla la lal ala bla bla bla la lal ala bla bla bla',
		priceExcludingSamagris: 1000,
		priceIncludingSamagris: 1100,
		image: 'https://avatars.githubusercontent.com/u/3167791?s=88&v=4',
	},
	{
		key: 4,
		title: 'Puja Five',
		description: 'la lal ala bla bla bla la lal ala bla bla bla la lal ala bla bla bla',
		priceExcludingSamagris: 1200,
		priceIncludingSamagris: 1300,
		image: 'https://avatars.githubusercontent.com/u/3167791?s=88&v=4',
	},
	{
		key: 5,
		title: 'Puja Six',
		description: 'la lal ala bla bla bla la lal ala bla bla bla la lal ala bla bla bla',
		priceExcludingSamagris: 1200,
		priceIncludingSamagris: 1300,
		image: 'https://avatars.githubusercontent.com/u/3167791?s=88&v=4',
	},
	{
		key: 6,
		title: 'Puja Seven',
		description: 'la lal ala bla bla bla la lal ala bla bla bla la lal ala bla bla bla',
		priceExcludingSamagris: 1200,
		priceIncludingSamagris: 1300,
		image: 'https://avatars.githubusercontent.com/u/3167791?s=88&v=4',
	},
];
