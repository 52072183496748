import { createTheme } from '@mui/material/styles';
import { overrides } from './Overrides';
const { components, typography } = overrides;

export const darkTheme = createTheme({
	typography,
	palette: {
		mode: 'dark',
	},
	components,
});

export const lightTheme = createTheme({
	typography,
	palette: {
		mode: 'light',
	},
	components,
});
