import { Grid } from '@mui/material';
import { AboutUsCard, PanchangCard, VideoCard, WelcomeCard } from 'components';

const HomePage = () => (
	<Grid container spacing={1}>
		<Grid item xs={12}>
			<WelcomeCard />
		</Grid>
		<Grid item xs={12}>
			<VideoCard />
		</Grid>
		<Grid item xs={12} md={4}>
			<PanchangCard />
		</Grid>
		<Grid item xs={12} md={8}>
			<AboutUsCard />
		</Grid>
	</Grid>
);

export default HomePage;
