import API_ENDPOINTS from 'constants/ApiEndpoints';
import { APP_ROUTES } from 'constants/AppRoutes';
import { useNavigate } from 'react-location';
import { useAppDispatch, useAppSelector } from 'store';
import { activeRoleReducer, errorReducer, successReducer } from 'store/app/appSlice';
import {
	panditDataInStore,
	savePanditProfileReducer,
	signInPanditReducer,
} from 'store/pandit/panditSlice';
import useAxios from 'utils/useAxios';

const useAuthPandit = () => {
	const { axiosRequest } = useAxios();

	const { isPanditSignedIn } = useAppSelector(panditDataInStore);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	// dispatch function that will set the user data in redux store and local storage while redirecting them to dashboard
	const dispatchFunc = (data?: {}) => {
		dispatch(
			signInPanditReducer({
				...data,
			})
		);

		dispatch(activeRoleReducer({ role: 'pandit' })); // TODO: change this to something that is hard to guess for malicious users in production
		navigate({ to: APP_ROUTES.DASHBOARD_PANDIT });
	};

	// function that will sign out the user from client as well as server
	const signOutFunction = () => {
		// trigger sign out only when pandit is signed in
		if (!isPanditSignedIn) return;
		axiosRequest({ url: API_ENDPOINTS.SIGN_OUT_PANDIT, method: 'delete' })
			.then((response) => {
				if (response.status === 204) {
					dispatch(
						signInPanditReducer({
							access_token: '',
						})
					);
					dispatch(savePanditProfileReducer({}));
					dispatch(activeRoleReducer({ role: '' }));
				}
			})
			.catch((error) => {
				console.log('🚀 ~ file: useAuthPandit.ts ~ line 38 ~ signOutFunction ~ error', error);
			});
	};

	// * sign up function

	const signUpPandit = (data: {}, setErrors: (fields: { [field: string]: string }) => void) =>
		axiosRequest({ url: API_ENDPOINTS.SIGN_UP_PANDIT, method: 'post', data })
			.then((response) => {
				dispatchFunc(response.data);
			})
			.catch((error) => {
				// setting errors for formik to display
				setErrors({
					name: error.response.data.error?.message?.name,
					email: error.response.data.error?.message?.email,
					mobile: error.response.data.error?.message?.mobile,
					password: error.response.data.error?.message?.password,
				});

				// only store error to redux if status code is not 422 or else the code will break because of typescript
				if (error.response.data.error.status === 422) return;
				// setting errors for redux store to display on top of form. useful for displaying not found error
				dispatch(errorReducer(error.response.data.error));
			});

	// * sign in function

	const signInPandit = (data: {}, setErrors: (fields: { [field: string]: string }) => void) =>
		axiosRequest({ url: API_ENDPOINTS.SIGN_IN_PANDIT, method: 'post', data })
			.then((response) => {
				dispatchFunc(response.data);
			})
			.catch((error) => {
				// setting errors for formik to display
				setErrors({
					emailOrMobile: error.response.data.error?.message?.emailOrMobile,
					password: error.response.data.error?.message?.password,
				});

				// only store error to redux if status code is not 422 or else the code will break because of typescript
				if (error.response.data.error.status === 422) return;
				// setting errors for redux store to display on top of form. useful for displaying not found error
				dispatch(errorReducer(error.response.data.error));
			});

	// * refresh token function

	const refreshJwtPandit = () =>
		axiosRequest({ url: API_ENDPOINTS.REFRESH_JWT_PANDIT, method: 'post' })
			.then((response) => {
				//  not using dispatch function declared on top as it has a redirect and we dont want pandit to be redirected to dashboard on each token refresh
				dispatch(
					signInPanditReducer({
						...response.data,
					})
				);
			})
			.catch((error) => {
				console.log('🚀 ~ file: useAuthPandit.ts ~ line 93 ~ useAuthPandit ~ error', error);
				dispatch(errorReducer(error.response.data.error));
			});

	// * sign out function

	const signOutPandit = () => {
		signOutFunction();
		navigate({ to: APP_ROUTES.HOME });
	};

	// * sign out function to use when logging in other two users

	const signOutPanditWithoutRedirect = () => {
		signOutFunction();
	};

	// * password reset function

	const passwordResetPandit = (
		data: {},
		setErrors: (fields: { [field: string]: string }) => void
	) => {
		axiosRequest({
			url: API_ENDPOINTS.CREATE_PASSWORD_RESET_LINK_PANDIT,
			method: 'post',
			data,
		})
			.then((response) => {
				dispatch(successReducer(response.data));
			})
			.catch((error) => {
				setErrors({
					emailOrMobile: error.response.data.error?.message?.emailOrMobile,
				});
				if (error.response.data.error.status === 422) return;
				dispatch(errorReducer(error.response.data.error));
			});
	};

	// * password reset function

	const setNewPasswordPandit = (
		data: {},
		setErrors: (fields: { [field: string]: string }) => void
	) => {
		axiosRequest({
			url: API_ENDPOINTS.SET_NEW_PASSWORD_PANDIT,
			method: 'post',
			data,
		})
			.then((response) => {
				dispatch(successReducer(response.data));
			})
			.catch((error) => {
				setErrors({
					newPassword: error.response.data.error?.message?.newPassword,
				});
				if (error.response.data.error.status === 422) return;
				dispatch(errorReducer(error.response.data.error));
			});
	};

	return {
		signUpPandit,
		signInPandit,
		signOutPandit,
		refreshJwtPandit,
		passwordResetPandit,
		setNewPasswordPandit,
		signOutPanditWithoutRedirect,
		isPanditSignedIn,
	};
};

export default useAuthPandit;
