import {
	Alert,
	AlertTitle,
	Box,
	Button,
	Checkbox,
	Container,
	Divider,
	FormControlLabel,
	Grid,
	Typography,
} from '@mui/material';
import { FormikControl, LinkButton } from 'common';
import { Form, Formik, FormikHelpers } from 'formik';
import useAuthAdmin from 'hooks/admin/useAuthAdmin';
import useAuthPandit from 'hooks/pandit/useAuthPandit';
import useAuthUser from 'hooks/user/useAuthUser';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-location';
import { useAppDispatch, useAppSelector } from 'store';
import { appDataInStore, clearErrorReducer } from 'store/app/appSlice';
import { SignInValidationSchema } from 'utils/FormValidationSchemas';
import { InitialSignInValueTypes, SignInComponentPropTypes } from './auth_types';

const SignInComponent = (props: SignInComponentPropTypes) => {
	const location = useLocation(); // useHistory alternative in react-location
	const { signInLink, resetPasswordLink, signUpLink } = props;
	const { error } = useAppSelector(appDataInStore);
	const dispatch = useAppDispatch();

	/* setting initial values for formik */
	const initialValues = {
		emailOrMobile: '',
		password: '',
	};

	const [currentLocation, setCurrentLocation] = useState('');

	// before mounting set the pathname to currentLocation variable to for submit fn to decide which switch block to run
	useEffect(() => {
		setCurrentLocation(location.current.pathname);
	}, [location]);

	// cleanup useEffect which will run every time the component unmounts to clear the errors from redux store
	useEffect(
		() => () => {
			dispatch(clearErrorReducer());
		},
		[dispatch]
	);

	const alertTitle = error.status === 401 ? 'Unauthorized' : 'Not found';

	// destructuring auth functions from //*useAuthUser hook
	const { signInUser, signOutUserWithoutRedirect } = useAuthUser();

	// destructuring auth functions from //*useAuthAdmin hook
	const { signInAdmin, signOutAdminWithoutRedirect } = useAuthAdmin();

	// destructuring auth functions from //*useAuthPandit hook
	const { signInPandit, signOutPanditWithoutRedirect } = useAuthPandit();

	const [showPassword, setShowPassword] = useState(false);

	/* handling form submission of formik */

	const onSubmit = (
		values: InitialSignInValueTypes,
		{ setErrors }: FormikHelpers<InitialSignInValueTypes>
	) => {
		// deciding which auth function to hit based on location at which user is at
		switch (currentLocation) {
			case '/sign-in-admin':
				signOutUserWithoutRedirect();
				signOutPanditWithoutRedirect();
				signInAdmin({ ...values }, setErrors);
				break;

			case '/sign-in-pandit':
				signOutUserWithoutRedirect();
				signOutAdminWithoutRedirect();
				signInPandit({ ...values }, setErrors);
				break;

			default:
				signOutAdminWithoutRedirect();
				signOutPanditWithoutRedirect();
				signInUser({ ...values }, setErrors);

				break;
		}
	};

	return (
		<Container maxWidth='xs' sx={{ mt: 5 }}>
			<Grid container justifyContent='space-between' alignItems='center' spacing={1}>
				<Grid item>
					<Typography variant='h5'>Sign In</Typography>
				</Grid>
				<Grid item>
					{/* checking if user is on login page or register page based on which show respective message */}
					{signUpLink ? (
						<LinkButton link={signUpLink} btnText='need an account?' />
					) : (
						<LinkButton link={signInLink} btnText='got an account?' />
					)}
				</Grid>
			</Grid>
			<Divider />

			{/* Show errors if user enters wrong credentials or account does not exists */}
			{error.status === 401 || error.status === 404 ? (
				<Alert
					severity='error'
					sx={{ mt: 1 }}
					variant='outlined'
					onClose={() => dispatch(clearErrorReducer())}>
					<AlertTitle>{alertTitle} </AlertTitle>
					{error.message}
				</Alert>
			) : null}

			<Formik
				initialValues={initialValues}
				validationSchema={SignInValidationSchema}
				onSubmit={onSubmit}>
				{() => (
					<Form>
						<FormikControl control='muiInput' name='emailOrMobile' />

						<FormikControl
							control='muiInput'
							type={showPassword ? 'text' : 'password'}
							name='password'
						/>

						<Box display={'flex'} justifyContent={'space-between'}>
							<FormControlLabel
								value='start'
								control={
									<Checkbox
										size='small'
										color='success'
										checked={showPassword}
										onChange={(e) => setShowPassword(!showPassword)}
									/>
								}
								label={<Typography variant='body2'>Show Password</Typography>}
								labelPlacement='end'
							/>

							{resetPasswordLink && (
								<LinkButton link={resetPasswordLink} btnText='forgot password?' />
							)}
						</Box>

						<Button variant='outlined' color='primary' type='submit' fullWidth>
							Submit
						</Button>
					</Form>
				)}
			</Formik>
		</Container>
	);
};

export default SignInComponent;
