import {
	Alert,
	AlertTitle,
	Box,
	Button,
	Checkbox,
	Container,
	Divider,
	FormControlLabel,
	Grid,
	Typography,
} from '@mui/material';
import { FormikControl, LinkButton } from 'common';
import { APP_ROUTES } from 'constants/AppRoutes';
import { Form, Formik, FormikHelpers } from 'formik';
import { useAuthAdmin, useAuthPandit, useAuthUser } from 'hooks';
import { useEffect, useState } from 'react';
import { useLocation, useMatches } from 'react-location';
import { useAppDispatch, useAppSelector } from 'store';
import { appDataInStore, clearErrorReducer, clearSuccessReducer } from 'store/app/appSlice';
import { SetNewPasswordValidationSchema } from 'utils/FormValidationSchemas';
import {
	InitialSetNewPasswordValueTypes,
	PasswordResetLinkCreatorComponentPropTypes,
} from './auth_types';

const SetNewPasswordComponent = (props: PasswordResetLinkCreatorComponentPropTypes) => {
	const dispatch = useAppDispatch();
	const location = useLocation(); // useHistory alternative in react-location
	const matches = useMatches();

	const { signInLink } = props;
	const { error, success } = useAppSelector(appDataInStore);

	const { setNewPasswordUser } = useAuthUser();
	const { setNewPasswordAdmin } = useAuthAdmin();
	const { setNewPasswordPandit } = useAuthPandit();
	/* setting initial values for formik */

	const initialValues = {
		newPassword: '',
	};

	const [showPassword, setShowPassword] = useState(false);
	const [token, setToken] = useState({});

	const [currentLocation, setCurrentLocation] = useState('');

	// before mounting set the pathname to currentLocation variable to for submit fn to decide which switch block to run
	useEffect(() => {
		setCurrentLocation(location.current.pathname);
		setToken(matches[0].params);
	}, [location]);

	const onSubmit = (
		values: InitialSetNewPasswordValueTypes,
		{ setErrors }: FormikHelpers<InitialSetNewPasswordValueTypes>
	) => {
		console.log('onSubmit ~ values', values);
		// deciding which auth function to hit based on location at which user is at
		switch (currentLocation) {
			case APP_ROUTES.SET_NEW_PASSWORD_ADMIN:
				setNewPasswordAdmin({ ...values, ...token }, setErrors);
				break;

			case APP_ROUTES.SET_NEW_PASSWORD_PANDIT:
				setNewPasswordPandit({ ...values, ...token }, setErrors);
				break;

			default:
				setNewPasswordUser({ ...values, ...token }, setErrors);
				break;
		}
	};

	const errorMessage =
		error.status === 401
			? 'Please generate a new password reset link.'
			: 'Something on our end went wrong, please check back later.';

	const alertTitle = error.status === 401 ? 'Link expired!' : 'Something went wrong!';

	return (
		<Container maxWidth='xs' sx={{ mt: 5 }}>
			<Grid container justifyContent='space-between' alignItems='center' spacing={1}>
				<Grid item>
					<Typography variant='h5'>Set new password</Typography>
				</Grid>
				<Grid item>
					{signInLink && (
						<LinkButton
							link={signInLink}
							btnText={success.status === 200 ? 'sign in' : 'remember password?'}
						/>
					)}
				</Grid>
			</Grid>
			<Divider />

			{/* Show errors if user enters wrong credentials or account does not exists */}
			{error.status >= 400 && error.status <= 500 ? (
				<Alert
					severity='error'
					sx={{ mt: 1 }}
					variant='outlined'
					onClose={() => dispatch(clearErrorReducer())}>
					<AlertTitle>{alertTitle} </AlertTitle>
					{errorMessage}
				</Alert>
			) : null}

			{/* Show errors if user enters wrong credentials or account does not exists */}
			{success.status === 200 ? (
				<Alert
					severity='success'
					sx={{ mt: 1 }}
					variant='outlined'
					onClose={() => dispatch(clearSuccessReducer())}>
					<AlertTitle>Success! </AlertTitle>
					{success.message}
				</Alert>
			) : null}

			<Formik
				initialValues={initialValues}
				validationSchema={SetNewPasswordValidationSchema}
				onSubmit={onSubmit}>
				{() => (
					<Form>
						<FormikControl
							control='muiInput'
							name='newPassword'
							type={showPassword ? 'text' : 'password'}
						/>

						<Box display={'flex'} justifyContent={'space-between'}>
							<FormControlLabel
								value='start'
								control={
									<Checkbox
										size='small'
										color='success'
										checked={showPassword}
										onChange={(e) => setShowPassword(!showPassword)}
									/>
								}
								label={<Typography variant='body2'>Show Password</Typography>}
								labelPlacement='end'
							/>
						</Box>

						<Button variant='outlined' color='primary' type='submit' fullWidth>
							Submit
						</Button>
					</Form>
				)}
			</Formik>
		</Container>
	);
};

export default SetNewPasswordComponent;
