import { List, ListItem, ListItemText } from '@mui/material';
import { MuiCard } from 'common';

const PanchangCard = () => (
	<MuiCard enableOverflow header='Daily Panchang'>
		<List>
			<ListItem>
				<ListItemText primary="Today's Date" secondary='12 december 2021 in hindi' />
			</ListItem>
			<ListItem>
				<ListItemText primary='Vrat & Tyohar' secondary='diwali' />
			</ListItem>
			<ListItem>
				<ListItemText primary='Shubh muhrt' secondary='9:30 am - 11:30 am' />
			</ListItem>
			<ListItem>
				<ListItemText primary='Ashubh muhrt' secondary='12:30 pm - 4:30pm' />
			</ListItem>
			<ListItem>
				<ListItemText primary='Sunrise time' secondary='6:30 am' />
			</ListItem>
			<ListItem>
				<ListItemText primary='Sunset time' secondary='6:30 pm' />
			</ListItem>
		</List>
	</MuiCard>
);

export default PanchangCard;
