import { Avatar, Box, Button, Divider, Grid, Typography } from '@mui/material';
import { FormikControl } from 'common';
import { Form, Formik, FormikHelpers } from 'formik';
import { useProfileAdmin, useProfilePandit, useProfileUser, useVerification } from 'hooks';
import { useEffect, useState } from 'react';
import { MdEdit, MdEditOff } from 'react-icons/md';
import { useAppSelector } from 'store';
import { adminDataInStore } from 'store/admin/adminSlice';
import { appDataInStore } from 'store/app/appSlice';
import { panditDataInStore } from 'store/pandit/panditSlice';
import { userDataInStore } from 'store/user/userSlice';
import { ProfileValidationSchema } from 'utils/FormValidationSchemas';
import AlertComponent from './AlertComponent';
import { genderOptions } from './FormStuffs';
import { profileFunction } from './ProfileFunctions';
import { InitialProfileValueTypes } from './profile_types';
import { UnVerifiedTooltip, VerifiedTooltip } from './Tooltips';

const Profile = () => {
	const { updateProfileUser } = useProfileUser();
	const { updateProfileAdmin } = useProfileAdmin();
	const { updateProfilePandit } = useProfilePandit();
	const { sendVerificationEmail, sendVerificationTextMessage } = useVerification();
	const { active_role } = useAppSelector(appDataInStore);
	const { user_profile } = useAppSelector(userDataInStore);
	const { admin_profile } = useAppSelector(adminDataInStore);
	const { pandit_profile } = useAppSelector(panditDataInStore);

	const [initialValues, setInitialValues] = useState({
		name: '',
		gender: '',
		address: '',
		email: '',
		mobile: '',
	});

	const [emailToVerify, setEmailToVerify] = useState('');
	const [mobileToVerify, setMobileToVerify] = useState('');
	const [clientsName, setClientsName] = useState('');
	const [isEditingDisabled, setIsEditingDisabled] = useState(true);

	const [isClientsEmailVerified, setIsClientsEmailVerified] = useState(false);
	const [isClientsMobileVerified, setIsClientsMobileVerified] = useState(false);

	// these are the functions that we are going to send to the profile function
	const functionPayload = {
		setInitialValues,
		setClientsName,
		setEmailToVerify,
		setMobileToVerify,
		setIsClientsEmailVerified,
		setIsClientsMobileVerified,
	};

	/* useEffect hook to set initial values of profile form, clients name to display, email to verify and mobile to verify */
	useEffect(() => {
		if (active_role === 'user') {
			profileFunction({
				profile_object_containing_client_info: user_profile,
				...functionPayload,
			});
		} else if (active_role === 'pandit') {
			profileFunction({
				profile_object_containing_client_info: pandit_profile,
				...functionPayload,
			});
		} else if (active_role === 'admin') {
			profileFunction({
				profile_object_containing_client_info: admin_profile,
				...functionPayload,
			});
		}
	}, [active_role, admin_profile, user_profile, pandit_profile]);

	const onSubmit = (
		values: InitialProfileValueTypes,
		{ setErrors }: FormikHelpers<InitialProfileValueTypes>
	) => {
		switch (active_role) {
			case 'admin':
				updateProfileAdmin({ ...values }, setErrors);
				break;

			case 'pandit':
				updateProfilePandit({ ...values }, setErrors);
				break;

			default:
				updateProfileUser({ ...values }, setErrors);
				break;
		}
	};

	return (
		<>
			{!isClientsEmailVerified ? (
				<AlertComponent
					title='email'
					function={() => sendVerificationEmail({ email: emailToVerify })}
				/>
			) : null}
			<br />
			{!isClientsMobileVerified ? (
				<AlertComponent function={() => sendVerificationTextMessage({ mobile: mobileToVerify })} />
			) : null}

			<br />

			<Box display='flex'>
				<Box mr={2}>
					<Avatar sx={{ width: 56, height: 56 }}>
						{clientsName && clientsName[0]?.toUpperCase()}
					</Avatar>
				</Box>

				<Box>
					<Typography variant='subtitle1'>
						Hello,
						<br /> {clientsName}
						{isClientsEmailVerified && isClientsMobileVerified ? (
							<VerifiedTooltip />
						) : (
							<UnVerifiedTooltip />
						)}
					</Typography>
				</Box>

				{/* Enable/Disabled profile editing */}
				<Box sx={{ ml: 'auto' }}>
					{isEditingDisabled ? (
						<Button
							startIcon={<MdEdit />}
							variant='outlined'
							color='secondary'
							onClick={() => setIsEditingDisabled(!isEditingDisabled)}>
							Enable editing
						</Button>
					) : (
						<Button
							startIcon={<MdEditOff />}
							variant='outlined'
							color='primary'
							onClick={() => setIsEditingDisabled(!isEditingDisabled)}>
							Disable editing
						</Button>
					)}
				</Box>
			</Box>

			<Formik
				initialValues={initialValues}
				validationSchema={ProfileValidationSchema}
				enableReinitialize
				onSubmit={onSubmit}>
				{() => (
					<Form>
						{/* Personal information grid */}
						<Divider>Personal Information</Divider>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6}>
								<FormikControl disabled={isEditingDisabled} control='muiInput' name='name' />
							</Grid>
							<Grid item xs={12} sm={6}>
								<FormikControl
									disabled={isEditingDisabled}
									control='muiSelect'
									name='gender'
									options={genderOptions}
								/>
							</Grid>
						</Grid>
						{/* Contact information grid */}
						<Divider>Contact Information</Divider>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6}>
								<FormikControl disabled={isEditingDisabled} control='muiInput' name='email' />
							</Grid>
							<Grid item xs={12} sm={6}>
								<FormikControl disabled={isEditingDisabled} control='muiInput' name='mobile' />
							</Grid>
						</Grid>
						{/* Address */}
						<Divider>Address</Divider>

						<FormikControl disabled={isEditingDisabled} control='muiTextArea' name='address' />
						<Box display={'flex'} justifyContent={'flex-end'}>
							<Button
								type='submit'
								variant='contained'
								color='primary'
								disabled={isEditingDisabled}>
								Update Profile
							</Button>
						</Box>
					</Form>
				)}
			</Formik>

			{/*Delete account will appear here in future */}
		</>
	);
};

export default Profile;
