import { createSlice } from '@reduxjs/toolkit';
import { CommonInitialStateTypes, ProfileValueTypes } from 'store/common_types';
import { RootState } from '..';

type InitialStateTypes = CommonInitialStateTypes & {
	isPanditSignedIn: boolean;
	pandit_profile: ProfileValueTypes;
};

const initialState: InitialStateTypes = {
	status: 'idle',
	isPanditSignedIn:
		JSON.stringify(localStorage.getItem('access_token_pandit')).length > 15 ?? false,
	access_token: localStorage.getItem('access_token_pandit') ?? '',
	pandit_profile: JSON.parse(localStorage.getItem('pandit_profile') ?? '{}'),
};

export const panditSlice = createSlice({
	name: 'pandit',
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {
		//? this functions will be triggered by dispatch() whenever auth does any operation related to authentication
		signInPanditReducer: (state, action) => {
			//* TODO: refactor this!
			state.access_token = action.payload.access_token;
			//! do not hardcode this value as it gets triggered by other user's auth actions , hard coding it will result into setting isSignedIn to true for all users
			state.isPanditSignedIn = action.payload.access_token !== '';
			localStorage.setItem('access_token_pandit', action.payload.access_token);
		},

		savePanditProfileReducer: (state, action) => {
			state.pandit_profile = action.payload;
			localStorage.setItem('pandit_profile', JSON.stringify(action.payload));
		},
	},
});

// exporting reducer so that we can dispatch this in desired page(s) which then will trigger associated action with the reducer
export const { signInPanditReducer, savePanditProfileReducer } = panditSlice.actions;

// exporting the app data object for consumption by page(s)
export const panditDataInStore = (state: RootState) => state.pandit;

// exporting the entire slice
export default panditSlice.reducer;
