// import { useAppSelector, useAppDispatch } from 'store';
// import { signInPanditReducer, panditDataInStore } from 'store/pandit/panditSlice';
import API_ENDPOINTS from 'constants/ApiEndpoints';
import { useAppDispatch } from 'store';
import { savePanditProfileReducer } from 'store/pandit/panditSlice';
import useAxios from 'utils/useAxios';

const useProfilePandit = () => {
	const { axiosRequest } = useAxios();

	const dispatch = useAppDispatch();

	/* function to fetch pandit's profile  */
	const fetchProfilePandit = () =>
		axiosRequest({ url: API_ENDPOINTS.PROFILE_PANDIT, method: 'get' })
			.then((response) => {
				dispatch(savePanditProfileReducer(response.data.profile));
			})
			.catch((error) => {
				console.log('error', error.response);
			});

	/* function to fetch pandit's profile  */
	const updateProfilePandit = (
		data: {},
		setErrors: (fields: { [field: string]: string }) => void
	) =>
		axiosRequest({ url: API_ENDPOINTS.PROFILE_PANDIT, method: 'patch',data })
			.then((response) => {
					// if update happens successfully, fetch the updated profile
					if (response.status === 204) {
						fetchProfilePandit();
					}
			})
			.catch((error) => {
				// if update fails, set the errors
				if (error.response.status === 422) {
					setErrors({
						name: error.response.data.error?.message?.name,
						gender: error.response.data.error?.message?.gender,
						address: error.response.data.error?.message?.address,
						email: error.response.data.error?.message?.email,
						mobile: error.response.data.error?.message?.mobile,
					});
				} else if (error.response.status === 409) {
					if (error.response.data.error.message.includes('email')) {
						setErrors({
							email: error.response.data.error?.message,
						});
					} else if (error.response.data.error.message.includes('mobile')) {
						setErrors({
							mobile: error.response.data.error?.message,
						});
					}
				}
			});

	/* function to fetch pandit's profile  */
	const deleteProfilePandit = () =>
		axiosRequest({ url: API_ENDPOINTS.PROFILE_PANDIT, method: 'delete' })
			.then((response) => {
				console.log('🚀 ~ file: useProfilePandit.ts ~ line 35 ~ .then ~ response', response);
			})
			.catch((error) => {
				console.log('error', error.response);
			});

	return { fetchProfilePandit, updateProfilePandit, deleteProfilePandit };
};

export default useProfilePandit;
